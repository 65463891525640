@import "@Styles/vars/_colors";

.customer-register {
  max-width: 21.875rem;
  margin: 0 auto;
}

.customer-register__title {
  margin-bottom: 1.125rem;
}

.customer-register__subtitle {
  font-size: .875rem;
  margin: 0 auto 2em;
}

.customer-register__input-field-error {
  background-color: transparent;
  color: $color-error;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  margin-bottom: 1em;
}
