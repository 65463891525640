.account-info {
  &__header,
  &__header-content {
    display: flex;
  }

  &__header-content {
    flex-wrap: wrap;
  }

  &__header {
    flex-direction: column;

    &-actions {
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      .subscribeButton {
        margin-bottom: 1rem;
        background-color: $color-orange;
        border:none;
      }
    }

    // CA-ONLY style rules
    &-actions--ca-only{
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      .subscribeButton {
        margin-bottom: 1rem;
        background-color: $color-orange;
        border:none;
      }
    }

    @include mq($until: tablet-w) {
      display: none;
    }
  }

  .action--subscription {
    width: 100%;
  }
}

.account-info__title {
  font-size: 1.125rem;
  margin: 0;

  @include mq($from: tablet-w) {
    font-size: 1.5rem;
    margin-bottom: .25em;
  }
}

.account-info__main {
  display: none;

  @include mq($until: tablet-w) {
    display: flex;
    flex-direction: column;
    background: $color-background-sidebar;
    color: $color-text-light;
    border-top: 1px solid;
  }
}

.account-info__name {
  position: relative;
  margin: 0 0.9rem;
  padding: 0.9rem 0;
	cursor: pointer;

  @include mq($from: tablet-w) {
    cursor: auto;
    pointer-events: none;
    margin: 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 6px;
    display: block;
    width: 16px;
    height: 1px;
    background: $color-white;
    transition: 0.3s ease-in-out;
    transition-property: transform;

    @include mq($from: tablet-w) {
      display: none;
    }
  }

  &::before {
    transform: translateY(-50%) rotate(90deg);
  }

  &::after {
    transform: translateY(-50%);
  }

  &.active {
    &::before,
    &::after {
      transform: translateY(-50%) rotate(0);
    }
  }
}

.account-info__details {
  margin: 0;
  line-height: 1.6;
  padding-top: 0;
  padding-left: 16px;
  padding-right: 16px;
  transition: $transition;
  transition-property: opacity, margin;

  @include mq($until: tablet-w) {
    display: grid;
    grid-template: auto / auto 1fr;
    padding-top: 0;
  }

  @include mq($from: tablet-w) {
    padding-left: 0;
  }

}

.account-info__subtitle {
  font-size: .875rem;
  font-family: $font-heading;

  text-transform: uppercase;

  @include mq($until: tablet-w) {
    font-size: .75rem;
    grid-column: 1 / 2;
  }
  @include mq($from: tablet-w){
    padding-bottom: 0;
  }
}

.account-info__detail {
  font-size: .875rem;
  margin-left: 0;

  &--email {
    padding-bottom: 0.5em;
    word-break: break-all;
  }
  @include mq($until: tablet-w) {
    grid-column: -1 / -2;
    margin-left: 1em;
  }
}
