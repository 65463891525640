.subscriber-confirmed {
  padding: 0 1.25em;
  text-align: center;
  margin: 50px 0 45px;

  &__icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.625em;
  }

  &__text {
    font-size: 0.875rem;
    margin: 0 auto;
    max-width: 275px;
  }
}
