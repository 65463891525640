.customer-login {
  max-width: 21.875rem;
  margin: 0 auto;
}

.customer-login__login,
.customer-login__guest {
  .customer-login--recover & {
    display: none;
  }
}

.customer-login__recover {
  .customer-login:not(.customer-login--recover) & {
    display: none;
  }
}

.customer-login__title {
  @include mq($until: tablet-w) {
    display: none;
  }
}
