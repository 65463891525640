/* stylelint-disable selector-max-class */
/* stylelint-disable selector-max-combinators */
/* stylelint-disable selector-max-specificity */
/* stylelint-disable selector-max-type */
/* stylelint-disable max-nesting-depth */
@import '../vars/layout';

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  @include mq($until: tablet) {
    display: none;
  }
}

th,
td {
  border-color: $color-border;
  border-style: solid;
  border-width: 0 0 1px;
  padding: 0.625em;
}

th {
  font-family: $font-heading;
  font-weight: 700;
}

tbody {
  tr {
    @include mq($until: tablet) {
      border-bottom: 1px solid $color-border;
      display: block;
      padding: 0.3125em 0;

      &:first-child {
        border-top: 1px solid $color-border;
      }

      td {
        border-width: 0;
        display: block;
        padding: 0.3125em;

        &[data-label] {
          display: flex;

          &::before {
            content: attr(data-label);
            font-weight: 700;
            margin-right: auto;
            padding-right: 1em;
          }
        }
      }
    }
  }
}
