/* stylelint-disable */
@import '../vars/layout';

.top-view-menu {
  background-color: $color-white;
  z-index: 1;
  min-height: 10.5rem;

  .menu-item {
    list-style: none;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
    font-family: $font-heading;
    font-weight: 700;
    line-height: normal;

    .menu-link {
      border-bottom: none;
      text-decoration: none;
      align-items: center;
      font-size: 1rem;
    }

    .icon {
      width: 2.5em;
      height: 3.438em;
      margin-right: 0.5em;
      border-bottom: 1px solid $color-border-lighter;;

      > svg {
        height: 2.75rem;
        width: 2.75rem;
        vertical-align: middle;
        display: inline-block;

        @include mq($until: tablet-w) {
          display: none;
        }
      }
    }
  }

  @include mq($until: tablet-w) {
    .menu {
      width: 100%;
    }

    .menu-item {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      padding: 0.75rem 0;
      font-size: 0.875rem;

      &:not(:last-child) a::after {
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover {
        > a {
          border-bottom: none;
        }
      }

      &:not(:last-child) {
        a::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block;
          width: 2.5rem;
          height: 1px;
          background-color: $color-border;
        }
      }
    }

    .icon {
      display: none;
    }
  }

  @include mq($from: tablet-w) {
    min-height: 10.625em;

    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 4.625rem;
      border-top: 1px solid $color-grey-light;
      border-bottom: 1px solid $color-grey-light;

      .menu-item {
        margin-left: 1.25em;
        margin-right: 1.25em;
      }

      .menu-link {
        display: flex;
        flex-flow: row nowrap;
      }

      .icon {
        height: 2.5em;
        margin-right: 0.313em;
        border: none;
      }
    }
  }
}
