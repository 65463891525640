.how-it-works-packaging {
  padding: 50px 0 20px;
  background-color: $color-white;

  @include mq($from: tablet-w) {
    padding-bottom: 80px;
  }

  &__title {
    text-align: center;
    margin-bottom: 20px;

    @include how_it_works_title;

    @include mq($from: tablet-w) {
      display: none;
    }
  }

  &__toggles {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .active {
      background-color: #d14124;
      color: #ffffff;
      pointer-events: none;
    }

    @include mq($from: tablet-w) {
      display: none;
    }

    .visually-hidden {
      position: absolute !important;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }

  &__toggle {
    color: $color-primary;
    min-width: 90px;
    text-transform: uppercase;
    padding: 9px;
    font: 700 12px $font-heading;
    background-color: $color-white;
    box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(198, 198, 198, 0.5);
    display: block;
    text-align: center;

    @include transition($transition-slow, background-color, border-color, box-shadow, color);
  }

  /* stylelint-disable selector-max-combinators, selector-max-specificity */

  &__method input[type='radio']:checked + &__toggle {
    color: $color-white;
    box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0);
    border-color: $color-accent;
    background-color: $color-accent;
  }
  /* stylelint-enable selector-max-combinators, selector-max-specificity */
}
