/* stylelint-disable */
@import "./layout";

@mixin space-between($direction, $distance, $align: null) {
  @if $direction == vertical {
    & > * {
      display: block;
      width: fit-content;
      margin-top: $distance;
      margin-bottom: $distance;
      @if $align == left {
        margin-right: auto;
      } @else if $align == right {
        margin-left: auto;
      } @else {
        margin-left: auto;
        margin-right: auto;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  } @else if $direction == horizontal {
    & > * {
      display: inline-block;
      width: fit-content;
      margin-left: $distance;
      margin-right: $distance;
      @if $align == top {
        margin-bottom: auto;
      } @else if $align == bottom {
        margin-top: auto;
      } @else {
        margin-top: auto;
        margin-bottom: auto;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  } @else {
    & > * {
      margin: $distance;
    }
  }
}

.flex-table {
  display: flex;
  flex-direction: column;
  margin: 0.625rem;

  .flex-thead,
  .flex-tbody,
  .flex-tfoot {
    display: block;
  }

  .flex-thead,
  .flex-tfoot {
    .flex-tr {
      padding: 0 1.25rem;
    }
  }

  .flex-tr {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border: solid 1px $color-border;
    padding: 1.25rem 0.625rem;
  }

  .flex-td,
  .flex-th {
    padding: 0 0.625rem;
    box-sizing: border-box;
    border: solid 1px $color-border;
    word-break: break-word;

    &.one-twelfth {
      min-width: calc(
        100% / 12
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(100% / 12);
    }

    &.one-eighth {
      min-width: calc(
        100% / 8
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(100% / 8);
    }

    &.one-sixth {
      min-width: calc(
        100% / 6
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(100% / 6);
    }

    &.two-thirds {
      min-width: calc(
        200% / 3
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(200% / 3);
    }

    &.one-third {
      min-width: calc(
        100% / 3
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(100% / 3);
    }

    &.one-quarter {
      min-width: calc(
        100% / 4
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(100% / 4);
    }

    &.one-half {
      min-width: calc(
        100% / 2
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(100% / 2);
    }

    &.three-quarters {
      min-width: calc(
        300% / 4
      ); // Fallback for browsers that don't fully support flex
      flex: 0 0 calc(300% / 4);
    }

    &.one-whole {
      width: 100%; // Fallback for browsers that don't fully support flex
      flex: 0 0 100%;
    }

    @include mq($until: laptop) {
      &.one-twelfth,
      &.one-eighth,
      &.one-sixth,
      &.two-thirds,
      &.one-third,
      &.one-quarter,
      &.one-half,
      &.three-quarters,
      &.one-whole {
        min-width: auto;
        flex: auto;
      }
    }

    &.fill {
      flex: 1;
    }

    &.align-right {
      @include space-between(vertical, 10px, right);
    }

    &.align-left {
      @include space-between(vertical, 10px, left);
    }

    @include mq($until: hd) {
      &.x-large {
        &--one-eighth {
          flex: 0 0 calc(100% / 8);
        }

        &--one-sixth {
          flex: 0 0 calc(100% / 6);
        }

        &--two-thirds {
          flex: 0 0 calc(200% / 3);
        }

        &--one-third {
          flex: 0 0 calc(100% / 3);
        }

        &--one-quarter {
          flex: 0 0 calc(100% / 4);
        }

        &--one-half {
          flex: 0 0 calc(100% / 2);
        }

        &--three-quarters {
          flex: 0 0 calc(300% / 4);
        }

        &--one-whole {
          flex: 0 0 100%;
        }
      }
    }
    @include mq($until: laptop) {
      &.large {
        &--one-eighth {
          flex: 0 0 calc(100% / 8);
        }

        &--one-sixth {
          flex: 0 0 calc(100% / 6);
        }

        &--two-thirds {
          flex: 0 0 calc(200% / 3);
        }

        &--one-third {
          flex: 0 0 calc(100% / 3);
        }

        &--one-quarter {
          flex: 0 0 calc(100% / 4);
        }

        &--one-half {
          flex: 0 0 calc(100% / 2);
        }

        &--three-quarters {
          flex: 0 0 calc(300% / 4);
        }

        &--one-whole {
          flex: 0 0 100%;
        }
      }
    }
    @include mq($until: tablet-w) {
      &.medium {
        &--one-eighth {
          flex: 0 0 calc(100% / 8);
        }

        &--one-sixth {
          flex: 0 0 calc(100% / 6);
        }

        &--two-thirds {
          flex: 0 0 calc(200% / 3);
        }

        &--one-third {
          flex: 0 0 calc(100% / 3);
        }

        &--one-quarter {
          flex: 0 0 calc(100% / 4);
        }

        &--one-half {
          flex: 0 0 calc(100% / 2);
        }

        &--three-quarters {
          flex: 0 0 calc(300% / 4);
        }

        &--one-whole {
          flex: 0 0 100%;
        }
      }
    }
    @include mq($until: tablet) {
      &.small {
        &--one-eighth {
          flex: 0 0 calc(100% / 8);
        }

        &--one-sixth {
          flex: 0 0 calc(100% / 6);
        }

        &--two-thirds {
          flex: 0 0 calc(200% / 3);
        }

        &--one-third {
          flex: 0 0 calc(100% / 3);
        }

        &--one-quarter {
          flex: 0 0 calc(100% / 4);
        }

        &--one-half {
          flex: 0 0 calc(100% / 2);
        }

        &--three-quarters {
          flex: 0 0 calc(300% / 4);
        }

        &--one-whole {
          flex: 0 0 100%;
        }
      }
    }
  }
}
/* stylelint-enable */
