
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.checkout-address-picker__button {
  align-items: center;
  animation: fade-in 0.5s ease forwards;
  border: none;
  display: flex;
  fill: $color-text;
  font-size: 0.875em;
  justify-content: center;
  margin: auto;
  padding: 0 2em 0 0.5em;
  text-decoration: underline;

  .icon {
    transition: transform $transition;
  }

  &--active {
    .icon {
      transform: rotate(-180deg);
    }
  }

}

.checkout-address-picker__map {
  height: 13.75rem;
  margin: 1em 0;

  @include mq($from: tablet) {
    margin: 1em -1em;
  }

  .stores-map {
    height: 100%;
    width: 100%;
  }
}
