
.checkout-fulfillment__addresses {
  .store__item-pin {
    position: relative;
    padding-top: 2.25em;
    counter-increment: store-address;
    font-family: $font-heading;
    font-size: 0.75em;

    &::after {
      content: counter(store-address);
      position: absolute;
      top: 0;
      left: 50%;
      color: $color-white;
      line-height: 1.75em;
      transform: translateX(-50%);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1.75em;
      height: 1.75em;
      border-radius: 50% 50% 0;
      background: $color-accent;
      color: $color-white;
      transform: translateX(-50%) rotate(45deg);
    }
  }
}

.store__item {
  border: 1px solid $color-secondary;
  padding-top: 1.25rem;
  padding-bottom: .625rem;
  text-align: center;
  margin: 1.25rem auto;
  max-width: 25em;
  overflow: hidden;

  &-header {
    font-size: .75rem;
    line-height: 1.66666;
    text-align: center;
  }

  &-pin {
    width: 73px;
    float: left;

    &__caption {
      font-family: $font-body;
      font-weight: 300;
      font-size: .75rem;
      text-transform: uppercase;
    }
  }

  &-oval {
    position: relative;
    display: inline-block;

    &::after {
      content: attr(data-index);
      color: $color-white;
      position: absolute;
      top: 0;
      left: 0.5px;
      width: 100%;
      text-align: center;
      font-family: $font-heading;
      font-size: .75rem;
      font-weight: 300;
    }
  }

  &-header, &-actions {
    width: calc(100% - 74px);
    float: right;
    border-left: 1px solid $color-secondary;
  }

  &-actions {
    margin-bottom: 10px;

    &--inner {
      margin-bottom: -10px;
    }
  }

  &-title {
    font-size: 1.125em;
    margin-bottom: 4px;
    padding-left: 0;

    @include mq($from: tablet) {
      text-align: center;
      padding-left: 10px;
    }
  }

  &-today {
    margin: 0.5rem 0 0.25rem;
    font-size: .75rem;

    sub {
      bottom: auto;
      text-transform: uppercase;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-left: 0;
    flex-flow: wrap;
  }

  &-button {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    margin: 0 5px 10px;
    padding: 8px;
    border: 0;
    font-size: .75rem;
    font-weight: 700;
    font-family: $font-heading;
    color: $color-primary;
    text-transform: uppercase;
    letter-spacing: 0.3px;

    @include mq($until: tablet) {
      color: $color-white;
      background-color: $color-primary;
    }
  }
}

.store-item__button {
  border-left: solid 1px $color-secondary;
  float: right;
  width: calc(100% - 4.625rem);

  .action {
    margin: 1em auto;
    padding: 0.375em;
  }

  .icon {
    margin-right: 0;
  }
}
