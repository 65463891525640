
/* stylelint-disable selector-max-class, selector-max-combinators */

.checkout-step--customer {
  .checkout-tab__content {
    padding-top: 1em;
    text-align: center;

    @include mq($from: tablet) {
      padding: 0 1em 1.5em;
    }

    .form__actions {
      margin-top: 2em;
    }

    form,
    button {
      margin: 0 auto;
      max-width: 21.75em;
    }
  }

/* stylelint-enable selector-max-class, selector-max-combinators */

  .action {
    font-size: 0.75em;
    padding: 1.5em 1.875em;
    margin-bottom: 1em;
    width: 100%;
  }

  .account__form--recovery-link {
    font-size: 0.875em;
    text-align: center;
  }

  .account__form--newsletter-copy {
    display: block;
    margin-bottom: 1.25em;
    margin-top: -0.375em;
  }

  .errors {
    color: $color-accent;
  }
}


.checkout-tab--signup .checkout-tab__content {
  @include mq($from: tablet) {
    padding: 2.5em 1em 2em;
    background: $color-grey-input;
  }
}
