/*
  Utility Mixins

  Styleguide: Utilities
*/

/*
  .noscroll

  prevents the element from being scrolled. Useful to put on 'body' for flyouts and modals.

  ```html
  // This page will not be scrollable
  <body class="noscroll">
  ```

  Style guide: Base - noscroll
*/
@mixin noscroll {
  overflow: hidden;
}

/*
  clickable

  Changes the mouse cursor to a pointer and does not allow text selection.

  ```scss
  button {
    @include clickable;
  }
  ```

  Styleguide: Utilities - clickable
*/
@mixin clickable {
  user-select: none;
  cursor: pointer;
}

/*
  hide-scrollbar

  Hides the scrollbar on the element.

  Compatible in Chrome 4+, Safari 5.1+, Opera 15+, iOS Safari 7.1+.

  ```scss
  .container {
    @include hide-scrollbar;
  }
  ```

  Styleguide: Utilities - hide-scrollbar
*/
@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

/*
  hide-number-spinner

  Hides the number spinner.

  ```scss
  input[type="number"] {
    @include hide-number-spinner;
  }
  ```

  Styleguide: Utilities - hide-number-spinner
*/
@mixin hide-number-spinner {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}

/*
  maintain-ratio

  Uses zero height and bottom padding to maintain the ratio
  of an element.

  $ratio - The width to height ratio of the element, separated
  by a space.

  ```scss
  .square {
    @include maintain-ratio(1 1);
  }

  .rectangle {
    @include maintain-ratio(4 3);
  }
  ```

  Styleguide: Utilities - maintain-ratio
*/
@mixin maintain-ratio($ratio: 1 1) {
  @if length($ratio) < 2 or length($ratio) > 2 {
    @warn '$ratio must be a list with two values.';
  }
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));
  width: $width;
  height: 0;
  padding-bottom: $height;
}

/*
  Apply transition properties to multiple properties.
  Avoids the need to specify transition properties for each property.

  Usage:   @include transition(0.3s ease-in-out, width, height, color);
  Output:  transition(width 0.3s ease-in-out, height 0.3s ease-in-out, color 0.3s ease-in-out);
*/
@mixin transition($transitions, $properties...) {
  $unfoldedTransitions: ();

  @each $property in $properties {
    $unfoldedTransitions: append($unfoldedTransitions, append($property, $transitions), comma);
  }
  transition: $unfoldedTransitions;
}

/*
 * Hide visually and from screen readers
 */

@mixin hidden {
  display: none !important;
}

/*
* Hide only visually, but have it available for screen readers:
* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*
* 1. For long content, line feeds are not interpreted as spaces and small width
*    causes content to wrap 1 word per line:
*    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
*/
@mixin visuallyhidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  white-space: nowrap; /* 1 */
}

/*
* Extends the .visuallyhidden class to allow the element
* to be focusable when navigated to via the keyboard:
* https://www.drupal.org/node/897638
*/
@mixin visuallyhidden--focusable {
  &:active,
  &:focus {
    position: static;
    overflow: visible;
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    white-space: inherit;
  }
}

/*
* Hide visually and from screen readers, but maintain layout
*/
@mixin invisible {
  visibility: hidden;
}

/*
* Removes indentation and bullets from lists
*/
@mixin ul--discrete {
  list-style: none;
  padding: 0;
  margin: 0;
}

/*
* Wraps long text when the words are too long
*/
@mixin wrap-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/*
* Renders a form with all fields on the same row
*/
@mixin inline-form {
  align-items: center;
  display: flex;

  input,
  select {
    flex-grow: 1;
    min-width: 0;
  }

  label,
  button {
    flex-shrink: 0;
  }
}

@mixin clearfix {
  *zoom: 1;

  &::before,
  &::after {
    content: " ";
    display: table; /* stylelint-disable-line declaration-property-value-blacklist */
  }

  &::after {
    clear: both;
  }
}

@mixin buttonReset {
  background: transparent;
  border: none;
  color: $color-primary;
  cursor: pointer;
  padding: 0;
  text-align: left;
  height: auto;
  min-height: 0;
  min-width: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  transition: none;
  text-transform: none;

  &:hover,
  &:active,
  &:focus {
    color: $color-primary;
    background-color: transparent;
    border-color: transparent;
  }
}

.root :global {
  --window-inner-height: 100vh;
}

$baseMobileHeaderHeight: 3.375rem;
$baseDesktopHeaderHeight: 4.625rem;
$tradeBarHeight: 2.5rem;
$announcementBarMobileHeight: 3.125rem;
$announcementBarDesktopHeight: 2.5rem;
$headerMobileHeight: $baseMobileHeaderHeight + $tradeBarHeight;
$headerDesktopHeight: $baseDesktopHeaderHeight + $tradeBarHeight;

$transition: 0.2s ease;
