.membership-subscription {
  position: relative;
  padding: 1.25rem 1.25rem 0;
  margin: 3.125rem auto 4.375rem;

  @include mq($until: tablet-w) {
    display: none;
  }

  &__title {
    font-size: 1.875rem;
    max-width: 33.125rem;;
    margin: 0 auto 50px;
  }

  &__close {
    @include buttonReset;
    position: absolute;
    top: 0;
    right: 0.938rem;
    width: 1rem;
    height: 1rem;
    fill: $color-primary;
  }

  &__actions {
    display: grid;
    grid-column-gap: 1.875rem;
    justify-content: center;
    grid-template-columns: auto auto;
  }
}
