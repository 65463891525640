.insulated-temperatures {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 700 16px $font-heading;
  margin-bottom: 20px;

  &__phase svg {
    display: block;
  }

  &__time,
  &__times,
  &__box {
    display: flex;
    align-items: center;
  }

  &__times {
    padding: 0 22px;
    display: grid;
    grid-template-areas: 'time';
    margin-left: 3px;
  }

  &__time {
    grid-area: time;
  }

  &__box {
    padding: 0 22px;
    border-left: 1px solid $color-tertiary-dark;
    height: 36px;
  }

  .js-night-time {
    opacity: 1;
  }
  
  .js-day-time {
    opacity: 0;
  }
}
