.membership-sidebar {
  background-color: $color-primary;
  color: $color-white;
  padding: 40px;
  text-align: center;

  @include mq($from: tablet-w) {
    padding: 50px 40px 20px;
  }

  &__icon {
    @include buttonReset;
    cursor: default;
    width: 32px;
    height: 32px;
    overflow: hidden;

    @include mq($until: tablet-w) {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%)
    }

    @include mq($from: tablet-w) {
      margin-left: auto;
    }

    path {
      stroke: $color-black;

      @include mq($from: tablet-w) {
        stroke: $color-white;
      }

      &:not([fill="none"]) {
        fill: $color-black;

        @include mq($from: tablet-w) {
          fill: $color-white;
        }
      }
    }
  }

  &__subscribe {
    grid-column: span 2;
  }

  &__image {
    margin: 0 auto;
    width: 220px;

    @include mq($from: tablet-w) {
      width: 180px;
    }
  }

  &__title {
    font-size: 18px;
    margin-bottom: 20px;

    @include mq($from: tablet-w) {
      font-size: 24px;
    }
  }

  &__subtitle {
    font-size: 14px;
    max-width: 340px;
    margin: 0 auto 20px;
  }

  &__subscription {
    position: relative;

    &-close,
    &-text {
      @include mq($from: tablet-w) {
        display: none;
      }
    }

    @include mq($until: tablet-w) {
      background-color: $color-white;
      padding-top: 1em;
      padding-bottom: 0.5em;
    }

    &-close {
      position: absolute;
      right: 15px;
      height: 20px;
      width: 20px;
    }

    &-text {
      position: relative;
      max-width: 270px;
      color: $color-primary;
      text-align: center;
      margin: auto;
    }

    &-cta {
      display: block;
      margin: 15px auto;
      width: 100%;

      @include mq($until: tablet-w) {
        max-width: 270px;
        width: 177px;
        height: 51px;
      }
    }
  }

  &__content {
    width: 100%;
  }
}