
.checkout-tip {
  box-sizing: border-box;

  .notice {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    background-color: $color-white;
    border: 1px solid $color-grey-light;
    color: $color-tertiary-dark;
    font-family: $font-button;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 0.938rem 0.625rem;
    min-width: 76px;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    text-align: center;
    text-transform: uppercase;
    transition: color $transition, background-color $transition;
    cursor: pointer;

    &:focus,
    &:hover,
    &.selected {
      color: $color-white;
      background-color: $color-primary;
    }

    &.selected {
      pointer-events: none;
    }
  }

  &__custom,
  &__custom-choice {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
  }

  &__custom-input {
    border: 1px solid $color-grey-light;
    height: 2.5rem;
    padding: 0 0.625rem;
    font-family: $font-montserrat;
    font-weight: 300;
    font-size: 1rem;
    color: $color-text-dark;
  }

  &__custom-input,
  &__current {
    margin-right: 1rem;
    width: 19.125rem
  }

  &__custom-submit.btn {
    .layout--checkout & { /* stylelint-disable-line selector-max-class */
      font-size: 0.75rem;
      min-height: 0;
      min-width: 8.125rem;
    }
  }

  &__current {
    font-family: $font-oswald;
    font-size: 1.125rem;
    font-weight: 700;
  }
}
