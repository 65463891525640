@mixin active_nav_item {
  color: $color-accent;

  &::before {
    background-color: $color-accent;
    border-color: $color-accent;
    height: 3.125rem;
  }
}

.how-it-works-navigation {
  @include mq($from: hiw-width) {
    position: fixed;
    z-index: 1;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 260px;
    padding: 0;
    transform: translateY(-50%);
    pointer-events: none;

    &[data-current='shipping'],
    &[data-current='enjoy'] {
      color: $color-white;
    }
  }

  &--hero {
    list-style-type: none;
    padding: 0 10px;
    margin-top: 30px;

    @include mq($from: hiw-width) {
      display: none;
    }
  }

  &__header {
    position: fixed;
    z-index: 1002;
    top: 0;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 3.125rem;
    padding: 0 10px;
    border: 0;
    visibility: hidden;
    transform: translateY(-100%);
    opacity: 0;
    background-color: $color-white;
    pointer-events: all;

    @include transition($transition-slow, opacity, transform, visibility, box-shadow);

    &.is-active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    .is-menu-open & {
      box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.1);
    }

    .how-it-works-navigation__items {
      transition: transform $transition-slow;
      margin: 0;
      padding: 0;
    }

    @include mq($from: hiw-width) {
      display: none;
    }
  }

  &__list-item {
    list-style: none;
  }

  &__item {
    color: $color-primary;
    position: relative;
    display: flex;
    align-items: center;
    border: 0;
    text-decoration: none;
    text-transform: uppercase;
    font: 700 18px $font-heading;
    transition: color $transition-slow;

    @include mq($from: hiw-width) {
      color: $color-primary;
      font-size: 24px;

      [data-current='shipping'] &,
      [data-current='enjoy'] & {
        color: $color-white;
      }
    }

    @include mq($until: hiw-width) {
      &--hero {
        display: none;
      }
    }

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 40px;
      margin-right: 20px;
      border: solid 1px rgba(198, 198, 198, 0.5);

      @include transition($transition-slow, background-color, border-color);

      @include mq($from: hiw-width) {
        height: 50px;
      }
    }

    &.is-active {
      @include active_nav_item;
    }

    .how-it-works-navigation__header & {
      padding: 14px 0;

      &::before {
        background-color: $color-accent;
        border-color: $color-accent;
        height: 1.25rem;
      }
    }
  }

  &__menu {
    position: fixed;
    z-index: 1001;
    top: 3.125rem;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0.625rem;
    background: $color-white;
    opacity: 0;
    transform: translateY(-100px);
    visibility: hidden;
    pointer-events: all;

    @include transition($transition-slow, opacity, transform, visibility);

    .how-it-works-navigation__items {
      list-style: none;
      padding-bottom: 50px;

      @include mq($from: hiw-width) {
        padding: 0;
      }
    }

    .is-menu-open & {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }

    @include mq($from: hiw-width) {
      position: static;
      display: block;
      width: auto;
      padding: 0;
      padding-left: 30px;
      background: transparent;

      .is-menu-open &,
      & {
        visibility: visible;
        opacity: 1;
        transform: none;
      }
    }
  }

  &__toggle-close,
  &__toggle-open {
    position: absolute;
    top: 50%;
    right: 10px;
    display: block;
    transition: opacity $transition-slow;
    transform: translateY(-50%);

    svg {
      display: block;
    }
  }

  &__toggle-close {
    opacity: 0;
  }


  .is-menu-open & {
    &__toggle-open {
      opacity: 0;
    }

    &__toggle-close {
      opacity: 1;
    }
  }
}
