
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-bg {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: $color-grey-light;
  }
}

.checkout-fulfillment {
  @include mq($from: tablet) {
    padding: 0 1em 1.5em;
  }

  .form {
    animation: fade-in 0.5s ease forwards;
    display: grid;
    grid-gap: 1em;

    @include mq($from: tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .form__field {
    margin-bottom: 0;
  }

  .form__actions {
    grid-column: 1 / -1;
  }

  .error {
    @include visuallyhidden;
  }
}

.checkout-fulfillment__title {
  text-align: center;

  @include mq($until: tablet) {
    font-size: 2rem;
  }

  &-icon {
    height: 1rem;
    width: 1rem;
    display: inline-block;
    fill: $color-white;
  }

  &--unlimited {
    width: calc(100% + 3rem);
    background: $color-accent-prime;
    padding: 0.625rem !important;
    margin: -1.5rem -1.5rem 0;
    text-align: center;
    font-size: 1rem;
    color: $color-white !important;
  }
}

.checkout-fulfillment__message {
  font-size: 0.75em;
  margin: auto;
  max-width: 25em;
  text-align: center;

  a, p {
    font-size: inherit;
    text-align: inherit;
  }
}

.checkout-fulfillment__addresses {
  counter-reset: store-address;
  margin-top: 2em;
  text-align: center;

  @include mq($from: tablet) {
    margin: 0;
  }

  h2 {
    @include mq($from: tablet) {
      font-size: 1.5em;
    }
  }

  .action {
    align-items: center;
    display: flex;
    font-size: 0.75em;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    min-width: 10.167em;
    padding: 1em 1.5em;
    width: auto;

    @include mq($from: tablet) {
      height: 3.334em;
      min-width: 12em;
      padding: 0;
    }
  }
}

.checkout-fulfillment__addresses-button {
  margin-top: 1em;

  .action {
    background: $color-white;
    min-width: 12em;
  }
}

.checkout-fulfillment__address {
  background: $color-grey-light;
  animation: fade-in-bg 1s ease forwards;
  margin: 0.5em 0 2em;
  padding: 1.5em;

  @include mq($from: tablet) {
    margin: 0 0 2em;
  }

  h2 {
    @include mq($from: tablet) {
      font-size: 1.5em;
    }
  }
}

.address-picker h2 {
  margin-bottom: 1.125rem;

  @include mq($from: tablet) {
    padding-top: 1em;
  }
}

.address-picker__map {
  button {
    min-height: 0 !important;
    min-width: 0 !important;
  }
}

.address-list {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(3, 1fr);
}

.checkout-fulfillment__customer,
.checkout-fulfillment__time,
.checkout-fulfillment__extra {
  animation: fade-in 0.5s ease forwards;

  h3 {
    margin-bottom: 1.125rem;
  }
}

.shipping-address {
  animation: fade-in 0.5s ease forwards;
  font-size: 0.875em;
  margin: 1em 0;
  text-align: center;
  word-wrap: break-word;
}

.shipping-address__name {
  display: block;
  font-family: $font-heading;
  font-weight: 600;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.shipping-address__link {
  animation: fade-in 0.5s ease forwards;
  font-weight: 500;
  font-size: 0.875em;
  text-align: center;

  button {
    border: none;
    border-bottom: none;
    background-color: inherit;
    color: #3d3935;
    fill: #3d3935;
    font-size: inherit;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}

.gift-option {
  margin-top: 1em;

  textarea {
    margin-top: 1em;
  }

  label {
    color: $color-text;
    margin-left: 0.5em;
  }

  .layout--checkout & input[type="checkbox"] { /* stylelint-disable-line selector-max-combinators, selector-max-specificity  */
    border-color: $color-primary;
  }
}
