.customer-addresses {
  text-align: center;

  .form__field {
    text-align: left;
  }
}

.customer-addresses__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;

  @include mq($from: tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: laptop) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.customer-addresses__actions {
  margin-top: 1.875rem;
}

.customer-address {
  border: 1px solid $color-secondary;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  text-align: center;
}

.customer-address__title {
  font-family: $font-heading;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.customer-address__name {
  font-family: $font-heading;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.customer-address__type {
  color: $color-accent;
  font-family: $font-heading;
  font-size: .875rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: .625rem;
}

.customer-address__address {
  font-size: 1rem;
  margin: 1rem 0
}

.customer-address__actions {
  margin-top: auto;

  .action {
    margin: .625rem .25rem 0;
    min-height: 0;
    min-width: 10em;
    padding: .25rem 1rem;
  }
}

.form--customer-address {
  margin: 2rem;
}

[data-address='root'] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

[data-line-count='1'] {
  flex-basis: 100%;
}

[data-line-count='2'] {
  width: 100%;

  @include mq($from: tablet) {
    flex-basis: 49%;
  }
}

[data-line-count='3'] {
  width: 100%;

  @include mq($from: tablet) {
    flex-basis: 32%;
  }
}
