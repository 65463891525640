@import "../vars/layout";

.section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background);
  min-height: var(--height, 20rem);

  &--fullscreen {
    max-height: 120rem;
    height: 90vh;

    @include mq($from: tablet) {
      max-height: 67.5rem;
    }
  }

  &--light {
    color: $color-text-light;
    fill: $color-text-light;
  }

  &--popout {
    z-index: 1;
    margin: -2rem 1rem;

    @include mq($from: tablet) {
      margin: -2rem 2rem;
    }
  }
}

.section__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: none;
  max-height: none;
  overflow: hidden;

  &--mobile ~ & {
    display: none;
  }

  @include mq($from: tablet-w) {
    &--mobile {
      display: none;
    }

    &--mobile ~ & {
      display: block;
    }
  }

  .responsive-image__image {
    object-fit: cover;
    object-position: center;
  }
}

.section__slider {
  .slider__arrow {
    background-color: $modal-overlay-white;
    top: 45%;
    z-index: 1;
  }

  .slider__arrow--next {
    right: 8px;

    @include mq($from: tablet) {
      right: 20px;
    }

    .cart & {
      /* stylelint-disable-line selector-max-combinators, selector-max-class */
      right: 0;
    }
  }

  .slider__arrow--prev {
    left: 8px;

    @include mq($from: tablet) {
      left: 20px;
    }

    .cart & {
      /* stylelint-disable-line selector-max-combinators, selector-max-class */
      left: 0;
    }
  }

  ~ .section__button {
    padding-bottom: 2rem;
  }

  .section__content & {
    @include wrapper_padded;
    position: relative;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .section__content__carousel & {
    @include wrapper_padded;
    position: relative;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .cart & {
    padding-right: 0;
    padding-left: 0;
  }
}

.section__content,
.section__content__carousel {
  position: relative;
  flex: 0 1 90rem;
  max-width: 90rem;
  text-align: center;
  width: 100%;
}

.section__content {
  padding: 0 1.25rem !important;
}

.section__content__carousel {
  padding: 0 14px 0 14px !important;
}

.section__heading {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 0 0 1rem;

  @include mq($from: tablet) {
    font-size: 1.875rem;
    line-height: 1.33333;
  }

  &--hero-title {
    color: $color-white;
    font-size: 1.875rem;
    line-height: 1.17;
    text-shadow: 0.625rem 0.5rem 1.125rem $color-black;

    @include mq($from: tablet) {
      font-size: 3.75rem;
    }
  }

  &--hero-subtitle {
    font-size: 1.125rem;
    font-family: $font-body;
    font-weight: 400;

    @include mq($from: tablet) {
      font-size: 1.875rem;
    }
  }

  &--title {
    font-family: $font-heading;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &--subtitle {
    font-family: $font-body;
    font-size: 1rem;
  }

  &--paragraph {
    font-size: 0.875rem;
    font-family: $font-body;
    font-weight: 300;
    margin: 1.5em auto;

    @include mq($from: tablet) {
      font-size: 1rem;
    }
  }
}

.section__title,
.section__subtitle,
.section__copy,
.section__cta {
  display: none;

  @include mq($from: tablet-w) {
    display: block;
  }

  &--mobile {
    display: block;

    @include mq($from: tablet-w) {
      display: none;
    }
  }
}

.section__title__summary {
  &--mobile {
    display: block;
  }
}

.section__flex-wrapper {
  display: flex;
  justify-content: space-between;

  @include mq($until: tablet) {
    flex-direction: column;
    justify-content: center;
  }
}

.section__paragraph {
  font-size: 0.875rem;
  margin: 1.5em auto;

  @include mq($from: tablet) {
    font-size: 1rem;
  }
}

.section__image {
  margin: 0 auto;
}

.section__icon {
  height: var(--icon-size, 2rem);
  margin: 0 auto;
  width: var(--icon-size, 2rem);

  svg {
    height: var(--icon-size, 2rem);
    width: var(--icon-size, 2rem);
  }
}

.section__indicator {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @include mq($until: tablet) {
    display: none;
  }
}

.section__button {
  .action {
    margin-bottom: 1.25rem;
    padding-top: 1.25em;
    line-height: 1;

    @include mq($from: tablet) {
      font-size: 1rem;
    }
  }
}

.tns-nav {
  position: relative;
  left: unset;
  display: flex;
  justify-content: flex-end;
  margin-top: -2.5rem;
  margin-bottom: 2.5rem;

  > button {
    width: 0.6rem;
    height: 0.6rem;
    padding: 0;
    margin: 0 0.3rem;
    border-radius: 50%;
    background: $color-grey-light;
    border: 0;
  }

  > .tns-nav-active {
    background: $color-grey;
  }

  @include mq($until: tablet) {
    justify-content: center;
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.25rem);
  }
}

.indicator {
  height: 4rem;
  width: 4rem;

  &--animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

  &--bounce {
    animation-name: bounce;
  }
}
