@import './mixins';

$transition-slow: 0.5s ease;

.hiw-enjoy {
  background: $color-white;
  position: relative;
  overflow: hidden;

  &__title {
    @include how_it_works_title;
    text-align: center;

    @include mq($from: tablet-w) {
      display: none;
    }
  }

  &__navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    @include mq($from: tablet-w) {
      display: none;
    }

    &-inner {
      background-color: $color-white;
      box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.2);
      border: solid 1px rgba(198, 198, 198, 0.5);
      display: flex;
      justify-content: center;
    }

    &-btn {
      background-color: transparent;
      border: 0;
      color: $color-text;
      display: block;
      font: 700 12px $font-heading;
      min-width: 90px;
      padding: 9px;
      text-align: center;
      text-transform: uppercase;

      @include transition($transition-slow, background-color, border-color, box-shadow, color);

      &.js-active {
        background-color: $color-accent;
        color: $color-white;
        pointer-events: none;
      }
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
    height: 490px;
    margin: 50px 10px;

    @include mq($from: tablet-w) {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      margin: 0;
      padding: 115px 0 95px;
    }

    @include mq($from: laptop) {
      padding: 140px 0 100px;
    }
  }

  &__tab-image {
    position: absolute !important;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    max-width: none !important; // needed to override snippet inline style
    height: 100%;
    background-size: cover;
    background-position: center center;
    opacity: 0;
    transform-origin: center center;

    @include transition($transition-slow, opacity);

    .responsive-image__image {
      object-fit: cover;
      object-position: center;
    }

    @include mq($until: tablet-w) {
      transform: none !important; // Important to disable ScrollMagic transform
    }

    &.js-active {
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-black;
      opacity: 0.1;

      @include mq($from: tablet-w) {
        width: 50%;
        opacity: 0.7;
      }
    }

    &--store {
      --object-position: center !important;

      &::after {
        @include mq($from: tablet-w) {
          background-color: #39455c;
        }
      }
    }

    &--defrost {
      overflow: hidden;

      &::after {
        opacity: 0.5;

        @include mq($from: tablet-w) {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color-black;
          opacity: 0.1;
        }
      }

      video {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--cook {
      &::after {
        @include mq($from: tablet-w) {
          background-color: #2b1512;
        }
      }
    }
  }

  &__tab {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 152px 30px 20px;
    color: $color-white;
    text-align: center;

    @include transition($transition-slow, opacity, visibility);

    @include mq($until: tablet-w) {
      opacity: 0;
      visibility: hidden;
    }

    @include mq($from: tablet-w) {
      position: static;
      width: auto;
      padding: 0;
    }

    @include mq($from: laptop) {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 25px 0 0;
      transform: translateX(calc(-50% + 25px));
    }

    &.js-active {
      @include mq($until: tablet-w) {
        visibility: visible;
        opacity: 1;
      }
    }

    & + & {
      @include mq($from: tablet-w) {
        margin-top: 80px;
      }
    }
  }

  &__tab-icon {
    border-radius: 50%;
    background: $color-white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 5px;
    height: 75px;
    width: 75px;

    @include mq($from: tablet-w) {
      animation: pulse 2s infinite;
      box-shadow: 0 0 0 0 $color-red;
      will-change: box-shadow;
    }

    @include mq($from: laptop) {
      position: absolute;
      bottom: calc(100% - 35px);
      right: 0;
      height: 50px;
      width: 50px;
      transition: transform $transition-slow;
      transform-origin: bottom center;
    }

    .js-active & {
      @include mq($from: laptop) {
        pointer-events: none;
        transform: scale(1.4);
      }
    }

    img {
      display: block;
      pointer-events: none;
      width: 100%;
    }
  }

  &__tab-content {
    position: relative;

    @include mq($from: laptop) {
      margin-right: 60px;
      padding-top: 40px;
      text-align: right;
      width: 185px;
    }
  }

  &__tab-title {
    font-family: $font-heading;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    padding: 0;

    @include mq($from: tablet-w) {
      font-size: 30px;
    }

    @include mq($from: laptop) {
      position: absolute;
      right: 0;
      bottom: calc(100% - 20px);
      margin: 0;
      line-height: 25px;
      transition: text-shadow $transition-slow, transform $transition-slow;
      transform-origin: bottom right;
    }

    .js-active & {
      @include mq($from: laptop) {
        transform: scale(2);
        text-shadow: 10px 8px 18px rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__tab-text {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  &__tab-link {
    color: $color-white;
  }

  &__quality {
    padding: 50px 10px 235px;
    margin-top: 50px;
    text-align: center;

    @include mq($until: tablet-w) {
      transform: none !important; // Important to disable ScrollMagic transform
    }

    @include mq($from: tablet-w) {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 0;
      margin-top: 0;
      padding: 0;
      background: none;
      transform: translateY(-50%);
      text-align: left;
    }

    &-container {
      @include mq($until: tablet-w) {
        position: relative;
      }
    }

    &-content {
      position: relative;
      background-color: rgba($color-accent, 0.7);
      padding: 10px;

      @include mq($from: tablet-w) {
        box-sizing: border-box;
        padding-right: 0;
        width: 227px;
      }
    }

    &-content-inner {
      position: relative;
      padding: 63px 20px 50px;
      border: 1px solid rgba($color-white, 0.7);
      border-top: 0;

      @include mq($from: tablet-w) {
        padding-right: 30px;
        padding-left: 50px;
        border-top: 1px solid rgba($color-white, 0.7);
        border-right: 0;
        border-left: 0;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: calc(50% - 50px);
        border-top: 1px solid rgba($color-white, 0.7);

        @include mq($from: tablet-w) {
          width: auto;
          height: calc(50% - 50px);
          border-top: 0;
          border-left: 1px solid rgba($color-white, 0.7);
        }
      }

      &::before {
        left: 0;

        @include mq($from: tablet-w) {
          top: 0;
        }
      }

      &::after {
        right: 0;

        @include mq($from: tablet-w) {
          top: auto;
          right: auto;
          bottom: 0;
          left: 0;
        }
      }
    }

    &-demi-circle {
      position: absolute;
      top: -40px;
      left: 50%;
      overflow: hidden;
      height: 30px;
      transform: translateX(-50%);

      @include mq($from: tablet-w) {
        top: 50%;
        left: -48px;
        width: 38px;
        height: auto;
        transform: translateY(-50%);
      }

      &::after {
        content: '';
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgba($color-accent, 0.7);
      }
    }

    &-icon {
      color: $color-white;
      position: absolute;
      top: -37px;
      left: 50%;
      transform: translateX(-50%);

      @include mq($from: tablet-w) {
        top: 50%;
        left: -45px;
        transform: translateY(-50%);
      }

      svg {
        height: 97px;
        width: 97px;
        fill: $color-white;
      }
    }

    &-title {
      color: $color-white;
      font-family: $font-heading;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.13;
      margin-bottom: 12px;
      padding: 0;
    }

    &-text {
      color: $color-white;
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
      word-break: break-word;

      @include mq($from: tablet-w) {
        text-align: left;
      }

      a {
        color: inherit;
      }
    }
  }
}

.hiw-quality__background {
  position: absolute;
  display: none !important;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  max-width: none !important; // needed to override snippet inline style
  height: 100%;
  background-size: cover;
  background-position: center center;
  transform-origin: center center;

  @include mq($until: tablet-w) {
    position: absolute !important;
    display: block !important;
  }

  @include transition($transition-slow, opacity);

  .responsive-image__image {
    object-fit: cover;
    object-position: center;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(209 65 36 / 50%);
  }
  50% {
    box-shadow: 0 0 0 15px rgb(209 65 36 / 0%);
  }
}