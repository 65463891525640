
@import '../ui/accessibility';
@import '../ui/button';
@import '../ui/heading';
@import '../ui/layout';
@import '../ui/media';
@import '../ui/text';

@import '../snippets/subscriber-confirmed';

/* stylelint-disable */

// Override base styles

.layout--checkout {
  // box-sizing: content-box;
  font-size: 16px;

  .section__header {
    margin-bottom: 1.5em;
  }

  a {
    border-bottom: none;
  }

  p,
  address {
    font-size: .875em;
    padding: 0;
  }

  b,
  strong {
    font-weight: 700;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    padding: 0;
  }

  .icon {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
  }

  .action {
    border-width: 1px;

    .icon:first-child {
      margin-right: 1ch;
    }
  }

  .modal__iframe {
    object-fit: unset;
  }

  .tooltip__text {
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
  }
}

// Override general checkout styles

.layout--checkout {
  .main__footer {
    .links {
      align-items: center;
      display: flex;
      font-size: .875em;
      justify-content: space-between;

      @include mq($until: mobile-w) {
        flex-direction: column-reverse;
      }
    }

    .link {
      color: $color-text;
      margin: .7142em 0;
      text-align: left;
      text-decoration: none;

      @include mq($until: mobile-w) {
        margin-top: .5em;
        text-align: center;
      }
    }

    // .btn,
    // .action {
    //   font-size: inherit;
    //   height: 2.858em;
    //   min-width: auto;
    //   padding: 0 1em;
    //   width: auto;
    // }
  }

  .modal {
    color: inherit;
    visibility: visible;
    margin: 0;
    width: auto;
    background: transparent;
    border: 0;
    max-width: none;
    padding: 1em;
  }

  .modal__content {
    padding: 1em;
    width: inherit;
  }

  .modal__header__title {
    color: $color-white;
  }

  .modal__close {
    top: 5%;
  }

  .modal__close-button {
    color: $color-white;
  }
}

// Override sidebar styles

.layout--checkout{
  #order-summary {
    display: none;
  }

  .sidebar {
    &::after {
      box-shadow: none;
    }
  }

  .sidebar__content {
    @include mq($from: tablet) {
      margin-left: -1.3125em;
      margin-right: -1.3125em;
    }
  }

  .sidebar__content {
    > .order-summary__section--total-lines {
      display: none;

      .total-line-table__footer .total-line:first-child {
        th,
        td {
          padding-top: .75em;

          &::before {
            content: none;
          }
        }
      }
    }

    .order-summary--is-collapsed + .order-summary__section--total-lines {
      @include mq($until: tablet) {
        display: block;
      }
    }
  }

  .section--reductions {
    display: block;

    &.hidden-on-desktop {
      // We always want to show and use the mobile
      // form for WFF's discount input
      display: block !important;
    }

    // .btn[type="submit"] {
    //   min-height: 3.125rem;

    //   @include mq($until: tablet) {
    //     min-width: 0;
    //   }
    // }
  }

  &.membership-checkout .section--reductions {
    display: none !important;
  }
}

.page--thank-you {
  [data-order-summary] {
    display: none;
  }
}

// Override payment step styles

.layout--checkout {
  .step[data-step="payment"] {
    h2 {
      font-size: 1.5rem;
      line-height: 1.3333;
      margin-bottom: 1.125rem;
    }
  }

  .review-block {
    // box-sizing: content-box;
  }

  .radio__label {
    font-size: .875rem;
    text-transform: uppercase;
  }

  .btn {
    @include button;
    border-radius: 0;
    font-size: 1rem;

    &[disabled] {
      background-color: $color-text-dark;
    }
  }
}


// Override subscription modal styles

.layout--checkout {
  a.subscription-modal__learn {
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 700;
    font-family: $font-button;
  }
}

// Override thank you page styles

.layout--checkout {
  .step[data-step="thank_you"] {
    .map__iframe {
      object-fit: unset;    // fixes map display on safari
    }

    .payment-method-list__item__amount {
      display: none;
    }

    .step__footer {
      display: none;
    }
  }

  .step__footer {
    .step__footer__info {
      @include mq($until: 749px) {
        margin-top: 2rem;
      }
    }
  }

  .thank-you__additional-content {
    position: relative;

    img[height="1"][width="1"] {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

[data-tip-amount] {
  display: none;
}
/* stylelint-enable */

.section__header .os-header__hanging-icon {
  position: relative;
  right: auto;
  top: auto;
  transform: none;
  margin-right: 0.75em;
}
