.form__status {
  font-size: 1rem;
  margin: 0 0 0.9375rem;
  padding: 0.9375rem;

  a,
  a:visited,
  a:hover,
  a:focus,
  a:active {
    color: inherit;
  }

  &--error {
    background-color: $color-white;
    border: 2px solid $color-error-border;
    color: $color-error;
    text-align: left;

    .form__message {
      color: $color-black;
      font-weight: bold;
      margin: 0 0 15px 0;
    }
  }

  &--success {
    background-color: $color-success-bg;
    border-color: $color-success-border;
    color: $color-success;
  }

  .form__errors {
    font-weight: bold;
    .error {
      text-align: left;
      margin: 0 0.625rem 0.625rem 0.625rem;
    }
    .error__link {
      pointer-events: none;
    }
    .error:last-child {
      margin-bottom: 0;
    }
  }
}
