@use "sass:map";
@import "../vars/breakpoints";
$page_gutter_mobile: 0.5rem;
$page_gutter: 1.25rem;

@mixin mq($from: null, $until: null) {
  @if ($from != null and $until == null){
    $from-string: map.get($mq-breakpoints, $from);
    @media (min-width: $from-string) { @content; }
  }
  @else if ($until != null and $from == null){
    $until-string: map.get($mq-breakpoints, $until);
    @media (max-width: $until-string) { @content; }
  } @else {
    $from-string: map.get($mq-breakpoints, $from);
    $until-string: map.get($mq-breakpoints, $until);
    @media (min-width: $from-string) and (max-width: $until-string) { @content; }
  }
}

@mixin wrapper($max-width: 90rem) {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
}

@mixin wrapper_padded {
  padding-left: $page_gutter_mobile;
  padding-right: $page_gutter_mobile;

  @include mq($from: tablet) {
    padding-left: $page_gutter;
    padding-right: $page_gutter;
  }
}

@mixin grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  column-gap: $page_gutter_mobile;
  row-gap: 2rem;

  @include mq($from: tablet) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2.5rem;
    row-gap: 3rem;
  }

  @include mq($from: laptop) {
    grid-template-columns: repeat(4, 1fr);
  }
}
