@import "@Styles/vars/fonts";
@import "@Styles/vars/colors";
@import "@Styles/vars/utilities";

.wrapper {
  position: relative;
}

.filterRow {
  padding: 1rem 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: $font-oswald;
  font-weight: 700;
  color: $color-primary;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color $transition;

  &.active {
    height: auto;
    min-height: 3.5rem;
    border-bottom-color: $color-secondary;
    z-index: 6;
  }
}

.filterIcon {
  height: 1.875rem;
  width: 1.875rem;
  object-fit: fill;
}

.arrowIcon {
  width: 1.25rem;
  height: 0.813rem;
  margin-left: 0.625rem;
  transition: transform $transition;

  path {
    transition: stroke $transition;
  }
}

.filterIconLabel {
  font-size: 0.875rem;
  font-weight: 500;
}

.button,
.clearButton {
  @include buttonReset;
  margin: 0 1rem;
  padding: 0.625rem 6px;
  font-family: $font-oswald;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-primary;
  transition: color $transition;

  &.active {
    color: $color-red;

    .arrowIcon {
      transform: rotateZ(180deg);

      path {
        stroke: $color-red;
      }
    }
  }
}

.clearButton {
  font-weight: 500;
  margin: 0;
  padding: 8px;
  font-size: 0.75rem;
  font-family: $font-montserrat;
  text-transform: lowercase;
  text-decoration: underline;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.optionsRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height $transition;

  padding: 0 1.25rem;
  background-color: $color-white;

  &.active {
    height: auto;
    min-height: 3.5rem;
    z-index: 6;
  }
}

.option {
  display: flex;
  align-items: center;
  margin: 1rem 1.375rem;

  label {
    font-family: $font-oswald;
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
    cursor: pointer;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    color: $color-red;
    font-weight: 500;
  }
}
