.how-it-works-scroll {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  &__button {
    @include buttonReset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: $color-primary;
    border: none;
    border-radius: 50%;
    transition: 0.2s;

    svg {
      height: 30px;
      width: 30px;
      fill: $color-white;
    }

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
  }
}
