.membership-table {
  $this: &;
  display: flex;
  justify-content: center;
  padding: 3.5rem 1.25rem;
  background: $color-grey-input;
  flex-basis: 62%;

  &__wrapper {
    text-align: left;
    width: 35rem;

    &--stacked {
      text-align: center;

      #{$this}__wrapper {
        max-width: 35.625rem;
        margin: auto;
        width: 100%;
      }
    }
  }

  &__heading {
    margin-top: 0;
  }

  &__header {
    padding: 3.5rem 0 2.5rem;

    @include mq($until: tablet) {
      text-align: center;
    }

    @include mq($from: tablet) {
      display: flex;
      justify-content: space-between;
      padding: 3.75rem 0 3.25rem;
      align-items: center;
    }
  }

  &__description {
    @include mq($until: tablet) {
      padding-bottom: 1.5rem;
    }

    @include mq($from: tablet) {
      text-align: left;
    }

    h2 {
      margin: 0 0 0.375rem;

      @include mq($until: tablet) {
        font-size: 1.25rem;
      }
    }

    p {
      margin: 0;
      font-weight: 400;

      @include mq($from: tablet) {
        text-align: center;
        font-size: 1.125rem;
      }
    }
  }
  /* stylelint-disable */

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .column--is-active {
      background: $color-greyish-brown;
      color: $color-white;
      fill: $color-white;
      box-shadow: 0 18px 50px 0 rgba(0, 0, 0, 0.35);
      border-bottom: 1px solid #3D3933;
    }
  }

  &__title {
    padding: 0.875rem 0.5rem;
    border-bottom: 1px solid $color-black;
    line-height: 0.875rem;
    display: table;
    height: 100%;
    width: 100%;
    color: inherit;

    @include mq($from: tablet) {
      padding: 1rem 1.125rem;
    }

    h4 {
      font-size: 0.75rem;
      line-height: inherit;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    }

    &--benefit {
      padding: 1rem 0;
      border-bottom: 1px solid $color-black;
      display: table;
      height: 100%;
      width: 100%;

      @include mq($until: laptop) {
        padding-left: 0.25rem;
      }

      h4 {
        font-size: 1rem;
        text-align: left;
        display: table-cell;
        vertical-align: middle;

        @include mq($until: mobile-w) {
          max-width: 6.25rem;
        }
      }
    }
  }
  /* stylelint-enable */

  &__benefit {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    padding: 1rem 0.5rem 0.75rem;
    border-bottom: 1px solid $color-black;
    align-self: self-end;
    height: 100%;

    @include mq($from: tablet) {
      padding: 1rem 1rem 0.75rem;
    }

    &--type {
      font-size: 1rem;
      text-align: left;
      padding: 0.875rem 0;
      line-height: 1.5rem;
      font-family: $font_heading;
      text-transform: uppercase;
      border-bottom: 1px solid $color-black;
      font-weight: 700;

      @include mq($until: laptop) {
        padding-left: 0.25rem;
        font-size: 0.875rem;
      }
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      vertical-align: bottom;
    }
  }

  h4 {
    margin: 0;
  }

  &__match-message {
    width: 100%;
    padding: 1.25rem;
  }

  &__link {
    border: 0;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    line-height: 1.25rem;
  }

  .local-disabled {
    color: $color-tertiary-dark;
  }

  .column--local {
    color: inherit;
  }

  .layout--account & {
    @include mq($from: laptop) {
      padding: 5rem 1.25rem 3rem 3.5rem;
    }

    @include mq($from: laptop-l) {
      padding: 5rem 8.875rem 3rem 7.375rem;
    }

    &__wrapper {
      width: auto;
    }
  }
}
