

.membership-summary {
  &__dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    &-category {
      position: relative;
      border: 0;
      border-right: 1px solid $color-grey-light;
      padding: 0 2rem;

      &:last-child {
        border: 0;
      }
    }

    @include mq($until: laptop) {
      grid-template-columns: 1fr;

      &-category {
        border: 0;
        border-bottom: 1px solid $color-grey-light;
        padding: 1rem 0.5rem;

        &:last-child {
          border: 0;
        }
      }
    }
  }

  &__subscription-highlight {
    color: $color-orange;
    font-weight: 700;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  &__cta {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 13.5rem;
    padding: 0 0.5rem;
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }

  &__cta-link {
    border: 0;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
  }

  &__success,
  &__error,
  &__notice {
    font-size: 0.85rem;
    font-weight: 400;
  }

  &__success {
    color: $color-success;
  }

  &__notice,
  &__highlight,
  &__error {
    color: $color-text-accent;
  }

  &__success,
  &__error {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 17rem;
  }
}
