.hiw-membership {
  background: $color-white;
  box-sizing: border-box;
  padding: 50px 0 98px;
  text-align: center;

  @include mq($from: tablet-w) {
    padding: 110px 0 98px;
  }

  @include mq($from: laptop) {
    position: relative;
    background-color: $color-grey-light;
    padding: 125px 0 100px;
  }

  &__content {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    height: 566px;

    @include mq($from: laptop) {
      height: 630px;
    }

    &::before { // Big circle around the content
      content: '';
      display: block;
      width: 566px;
      height: 566px;
      border-radius: 50%;
      background-color: $color-text;

      @include mq($from: laptop) {
        width: 630px;
        height: 630px;
      }
    }
  }

  &__middle {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 566px;
    padding: 91px 20px 0;

    @include mq($from: laptop) {
      max-width: 630px;
      height: 630px;
      padding-top: 80px;
    }
  }

  &__logo {
    position: relative;
    height: 120px;
    width: 120px;
    margin-bottom: 5px;

    @include mq($from: laptop) {
      margin-bottom: 30px;
    }
  }

  &__middle-content {
    color: $color-white;
    width: 100%;
    max-width: 320px;
  }

  &__pre-title {
    font-family: $font-oswald;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 5px;
  }

  &__title {
    font-family: $font-oswald;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    margin: 0 auto 15px;
    padding: 0 20px;
  }

  &__text {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
  }

  &__cta {
    box-sizing: border-box;
    background: linear-gradient(180deg, #a38c71 0%, #8f7b64 6.25%, #685642 100%);
    border: 1px solid $color-accent-prime;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 25px;
    color: $color-white;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;

    @include mq($from: laptop) {
      margin-top: 35px;
    }

    &:hover,
    &:focus {
      background: linear-gradient(180deg, #a38c71 0%, #8f7b64 6.25%, #685642 100%);
    }

    .hide-subscription & {
      display: none;
    }
  }
}
