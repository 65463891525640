.hiw-questions {
  background-color: $color-white;
  box-sizing: border-box;
  border-top: 1px solid $color-secondary-lighter;
  padding: 50px 20px;
  text-align: center;
  width: 100%;

  @include mq($from: tablet) {
    padding: 100px 20px;
  }

  &__title {
    font-family: $font-heading;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.33px;
    color: $color-text;
    margin-bottom: 30px;
    text-transform: uppercase;

    @include mq($from: tablet) {
      font-size: 30px;
    }
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mq($from: tablet) {
      flex-direction: row;
    }
  }

  &__cta {
    & + & {
      margin-top: 30px;

      @include mq($from: tablet) {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }
}
