.account-nav {
  position: relative;
  border-top: 1px solid $color-text-light;
  margin-top: 3.125rem;
  padding-top: 1.25rem;

  @include mq($until: tablet-w) {
    background: $color-background-sidebar;
    border: 0;
    color: $color-text-light;
    padding: 0.5rem 1rem;
    margin-top: 1px;
  }
}

.account-nav__dropdown{
  @include ul--discrete;

  @include mq($until: tablet-w){
    &--opened {
      padding-bottom: 1rem;
    }
  }


}

.account-nav__chevron {
  position: absolute;
  top: 12px;
  right: 13px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  transition: transform $transition;

  &--opened{
    transform: translateY(0%) rotate(180deg);
  }

  svg {
    fill: $color-white;
  }
  @include mq($from: tablet-w) {
    visibility: hidden;
  }

}

.account-nav__list {
  @include ul--discrete;
}

.account-nav__item {
  &:nth-child(n + 2) {
    margin-top: .75rem;
  }
}

.account-nav__link {
  cursor: pointer;
  align-items: center;
  color: $color-text-light;
  display: flex;
  font-family: $font-heading;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.875rem;
  padding-bottom: .625rem;
  text-decoration: none;
  text-transform: uppercase;

  @include mq($from: tablet) {
    font-size: 1.125rem;
  }

  @include mq($from: tablet-w)
  {
    &:focus,
    &:hover,
    &--active {
      color: $color-text-accent;
    }
  }

  &--selected{
    color: $color-text-accent;
    padding: 0.25rem 0;

    @include mq($from: tablet-w) {
      display: none;
    }

    .account-nav__label {
      &::after{
        display: none;
      }
    }

  }
}

.account-nav__icon {
  display: inline-block;
  fill: $color-text-light;
  height: 1.875rem;
  margin-right: .625rem;
  stroke: $color-text-light;
  width: 1.875rem;
}

.account-nav__label {
  position: relative;
  flex-grow: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: -.625rem;
    left: 0;
    width: 2.5rem;
    height: 1px;
    border-top: 1px solid $color-text-light;
    transform: scaleX(1);
    transform-origin: left;
    transition: $transition;

    /* stylelint-disable selector-max-specificity */
    @include mq($from: tablet-w) {
      .account-nav__link:focus &,
      .account-nav__link:hover &,
      .account-nav__link--active & {
        border-color: $color-text-accent;
        transform: scaleX(1.5);
      }
    }

    /* stylelint-enable selector-max-specificity */
  }
}
