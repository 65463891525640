
.checkout-tab--delivery {
  @include mq($from: tablet) {
    &.checkout-tab--active .checkout-tab__summary { /* stylelint-disable-line selector-max-class */
      padding-bottom: 1em;
    }

    .checkout-tab__content {
      background: $color-grey-input;
    }
  }
}

.checkout-step--shipping {
  @include mq($until: tablet) {
    display: grid;
    grid-gap: 1em;
    grid-template: ". ." "content content" / 1fr 1fr;
  }

  .checkout-tab {
    @include mq($until: tablet) {
      display: contents;
    }

    &--active {
      .checkout-tab__summary { /* stylelint-disable-line  selector-max-combinators */
        @include mq($until: tablet) {
          box-shadow: 0 0 0 3px inset $color-text;
        }
      }
    }
  }

  .checkout-tab__summary {
    display: flex;
    flex-wrap: wrap;

    @include mq($until: tablet) {
      display: block;
      box-shadow: 0 0 0 1px inset $color-text;
      padding: 0.444em;
    }
  }

  .checkout-tab__content {
    grid-area: content;
  }

  .checkout-tab__button {
    @include mq($until: tablet) {
      margin-left: 1em;
      text-align: left;
      width: calc(100% - 1em);
    }
  }
}

.subscribe-button {
  background-color: $color-accent-prime;
  border: 0;
  width: 177px;
  font-family: $font-button;
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $color-white;
  line-height: 1.2;
  margin-left: auto;
  padding: 10px 18px;

  @include mq($until: tablet) {
    display: none;
  }
}

.checkout-shipping-warning {
  display: none;
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  padding-right: 1.2rem;

  @include mq($until: tablet) {
    padding-bottom: 1rem;
  }

  .checkout-step--single_method & {
    display: block;
  }

  &__message {
    font-weight: 500;
    color: $color-text-dark;
    margin: 1rem 0;
  }

  .warning__currency {
    color: $color-text-accent;
  }
}
