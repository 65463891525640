.membership-modal {
  padding: 40px 20px 20px;
  color: $color-primary;

  @include mq($until: tablet) {
    width: calc(100vw - 20px);
  }

  &__title {
    margin: 0 0 30px;
    text-align: center;

    @include mq($from: tablet) {
      margin-bottom: 70px;
    }
  }

  &__card {
    position: relative;
    width: 100%;
    max-width: 280px;
    margin: 0 auto 55px;
    border-radius: 10px;
    background-color: $color-primary;
  }

  &__card-img {
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
    display: block;
    opacity: 0.5;
    width: 100%;
  }

  &__card-content {
    color: $color-white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 30px 25px 15px;
  }

  &__card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__card-logo {
    width: 50px;
  }

  &__card-name {
    font-family: $font-oswald;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }

  &__card-label {
    font-size: 12px;
    font-family: $font-oswald;
    text-transform: uppercase;
  }

  &__card-data {
    font-size: 14px;
  }
}
