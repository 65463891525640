
.subscription-info {
  position: relative;
  display: none;
  margin-bottom: 2rem;
  border-bottom: solid 1px $color-border-light;
  text-align: center;

  @include mq($until: tablet) {
    display: block;
  }

  &__close {
    position: absolute;
    top:0;
    right: 0;
    padding: 0;
    border: 0;
    cursor: pointer;

    svg {
      height: 32px;
      width: 32px;
      display: block;
    }
  }

  p#{&}__text {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    padding-top: 14px;

    span {
      display: inline-block;
      width: 256px;
    }
  }

  &__button {
    width: 177px;
    margin-bottom: 2rem;
    border: none;
    background-color: $color-accent-prime;
    color: $color-white;
    font-family: $font-button;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    padding: 12px 18px;
  }
}
