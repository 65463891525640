.insulated-delivery {
  margin: 0 10px;
  text-align: center;
  padding: 20px 0 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  @include mq($until: tablet-w) {
    transform: none !important; // Important to disable ScrollMagic transform
  }

  @include mq($from: tablet-w) {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
    width: 34.789%;
    padding: 50px 50px 30px;
  }

  &__outer {
    overflow: hidden;

    @include mq($from: tablet-w) {
      overflow: visible;
    }


    @include mq($from: laptop-l) {
      max-width: 1440px;
      margin-left: auto;
      margin-right: 5%;
    }
  }

  &__inner {
    display: flex;
    width: 200%;
    transition: transform $transition-slow;

    @include mq($from: tablet-w) {
      transform: none !important; // Need to disable the tabber slide transition on desktop
      width: auto;
      justify-content: flex-end;
      padding: 0 10px;
    }
  }

  &--bag {
    background-color: $color-white;
    border: 1px solid $color-secondary-lighter;
  }

  &--system {
    background-color: #6085bc;
    color: $color-white;
    position: relative;
    z-index: 1;
  }

  &__background {
    position: absolute;
    z-index: -1;
    object-fit: cover;
    top: 0;
    height: 100%;
    width: 100%;

    .responsive-image {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      max-width: none !important; // needed to override snippet inline style
      height: 100%;
      background-size: cover;
      background-position: center center;
      transform-origin: center center;

      @include transition($transition-slow, opacity);

      &__image {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__title {
    @include how_it_works_title;

    @include mq($from: tablet-w) {
      font-size: 60px;
    }
  }

  &__subtitle {
    display: block;
    font-size: 12px;
    line-height: normal;

    .insulated-delivery--bag & {
      color: $color-primary;
      text-shadow: none;
    }

    @include mq($from: tablet-w) {
      font-size: 30px;
      line-height: 1.17;
      padding: 0;
    }
  }

  &__image {
    box-sizing: border-box;
    width: 100%;

    .responsive-image {
      margin: 0 auto;
    }

    img {
      max-width: 100%;
    }
  }

  &__body-title {
    font-size: 16px;
    max-width: 196px;
    margin-left: auto;
    margin-right: auto;
    line-height: normal;
    margin-bottom: 10px;
  }

  &__body {
    margin: 0;
    padding-bottom: 10px;
    font-size: 14px;
  }
}
