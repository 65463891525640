
@mixin animation($str) {
  animation: #{$str};
}

@keyframes fadeout {
  0%   { opacity: 1; }

  100% { opacity: 0; }
}

@keyframes ellipsis {
  0% { content: ''; }

  25% { content: '.'; }

  50% { content: '..'; }

  100% { content: '...'; }
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(209, 65, 36, 0.5); }

  50% { box-shadow: 0 0 0 15px rgba(209, 65, 36, 0); }
}
