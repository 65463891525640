.icon {

  &-link {
    cursor: pointer;
  }

  &-disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
}
