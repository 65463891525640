.account-section {
  padding: 0rem 1.125rem 0rem;

  &--membership {
    padding-left: 0;
    padding-right: 0;
  }

  @include mq($from: tablet) {
    min-width: 100%;
  }
}

// CA-ONLY style rules
.account-section--ca-only {
  padding: 0 1.125rem 0;

  &--membership {
    padding-left: 0;
    padding-right: 0;
  }

  @include mq($from: tablet) {
    min-width: 100%;
  }
}

.account-section__header {
  margin-bottom: 3.375rem;
  text-align: center;
}

.account-section__none {
  display: none;
}

.account-section__title {
  font-size: 1.625rem;
  margin: 0;

  @include mq($from: tablet) {
    font-size: 1.875rem;
  }

  .icon {
    display: block;
    margin: 0 auto 0.625rem;
    height: 3.75rem;
    width: 3.75rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    stroke: $color-text;
  }
}

.account-section__welcome {
  font-size: 2.25rem;
  margin-top: 0.5rem;

  @include mq($from: tablet) {
    font-size: 3.75rem;
  }
}

.account-section__membership-level {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  margin-bottom: 5rem;
}

.account-section__main {
  text-align: center;
}

.account-section__cta {
  display: flex;
  justify-content: center;
  align-items: center;
}
