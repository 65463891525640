
.product td,
.product th {
  vertical-align: top;
  padding-left: 1.25em;
  padding-top: 2em;
  padding-bottom: 0;
}

.product:first-child th {
  padding-top: 0;
}

.product td:first-child,
.product th:first-child {
  padding-left: 0;
  text-align: left;
}

.product__description__name {
  font-family: $font-heading;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: $color-text;
}

.product__price {
  font-family: $font-body;
  font-size: 1.3125em;
  font-weight: 700;
  letter-spacing: 0.01905em;
  text-align: left;
  color: $color-text;
}

.product__price__details {
  display: block;
  font-size: 0.703em;
  font-weight: 500;

  &--note {
    font-style: italic;
    font-weight: 300;
  }
}

.product-thumbnail {
  width: 5.715em;
  height: 5.715em;
}

.product-thumbnail,
.product-thumbnail__wrapper,
.product-thumbnail::after {
  border-radius: 0;
  border: none;
}

.product-thumbnail__quantity {
  padding: 0.15em 0.75em;
  font-family: $font-heading;
  font-size: 0.75em;
  line-height: 1.55em;
}
