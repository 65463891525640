@import './fonts';

@mixin button {
  background: $color-text;
  border: 1px solid $color-text;
  border-radius: 2rem;
  color: $color-white;
  display: inline-block;
  fill: $color-white;
  font-family: $font-button;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.2;
  // min-height: 3.66666em;
  min-width: 12em;
  padding: 1em 1.25em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 200ms, border-color 200ms;

  &:focus,
  &:hover,
  &:active {
    background-color: $color-text-dark;
    border-color: $color-text-dark;
    color: $color-white;
    fill: $color-white;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
}
