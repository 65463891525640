@import '../vars/utilities';

.skip-link {
  @include visuallyhidden;
  @include visuallyhidden--focusable;
}

[v-cloak],
[data-aria-hidden='true'] {
  @include hidden;
}
