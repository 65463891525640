.customer-membership {
  padding-bottom: 3.75rem;
  margin-top: 3rem;
  padding-top: 3rem;

  @include mq($from: tablet) {
    display: flex;
    flex-direction: column;
  }

  &__details {
    display: flex;
    justify-content: center;
  }

  &__spacer {
    border: 1px solid $color-secondary;
    margin: 0;
  }

  &__delivery-message {
    max-width: 31.5rem;
    margin: 3.75rem auto 0;
  }

  &__questions {
    margin-top: 4.625rem;
    margin-bottom: 1rem;
  }

  .membership-modal & {
    border: none;
    padding: 0;

    @include mq($from: tablet) {
      grid-column-gap: 1.25rem;
      grid-template-columns: auto auto;
    }
  }

  &__qr-code {
    @include mq($until: tablet) {
      margin-bottom: 0.938rem;
    }

    @include mq($from: tablet) {
      justify-self: flex-end;
    }
  }

  &__qr-message {
    max-width: 13.5rem;
    font-size: 0.875rem;
    margin: 0.625rem auto 0;
    line-height: 1.4;
  }

  &__wallet {
    justify-self: start;
    align-self: center;
  }

  &__paylink {
    display: inline-block;

    img {
      display: block;
    }
  }

  &__tiers {
    display: flex;

    @include mq($until: 1250px) {
      flex-direction: column;
    }
  }
}
