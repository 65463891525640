/* stylelint-disable */

@import "../ui/flex-table";

.customer-orders {
  text-align: left;

  .action {
    font-size: 0.75rem;

    @include mq($from: tablet) {
      font-size: 0.875rem;
    }
  }

  .title {
    font-family: $font-heading;
    font-weight: 700;
    text-transform: uppercase;
    word-break: break-word;
  }

  .subtitle {
    font-family: $font-heading;
    font-weight: 500;
    text-transform: uppercase;
  }

  .icon {
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

  .flex-table {
    border: none;
    font-size: 1rem;

    @include mq($until: laptop) {
      font-size: 1rem;
    }

    @include mq($until: laptop) {
      .flex-thead {
        text-align: center;
        font-size: 1.1rem;
      }
    }

    .flex-thead,
    .flex-body,
    .flex-tfoot,
    .flex-td,
    .flex-th {
      border: none;

      p {
        text-align: left;
        margin: 0;
        line-height: 1.5;

        .icon {
          display: block;
          font-size: 2rem;
        }
      }
    }

    .flex-th {
      &:nth-child(5) {
        margin-right: -3.75em;
      }
    }

    .flex-thead {
      .flex-tr {
        margin: 0;
        border: none;
        padding: 0 40px;
        justify-content: flex-start;

        @include mq($until: laptop) {
          padding: 0;
        }

        .flex-th {
          font-family: $font-heading;
          text-transform: uppercase;
        }
      }
    }

    .flex-tbody {
      padding: 0 10px 20px;
      @include mq($until: laptop) {
        padding: 0;
      }
    }

    .flex-tr {
      margin-top: 20px;
      flex: 1 0 auto;
      border: 1px solid $color-secondary;
      padding: 20px;
    }

    .order__actions {
      @include mq($until: laptop) {
        button {
          margin-left: 0;
        }
      }
    }

    .account__order--fulfillment {
      @include mq($until: laptop) {
        width: 41.65%;
        min-width: 41.65%;
      }
    }

    .account__order {
      position: relative;

      &-button {
        display: flex;
        justify-content: center;
        align-items: center;

        &-arrow {
          margin-left: 10px;
        }
      }

      @include mq($until: laptop) {
        justify-content: flex-start;
      }
    }

    .account__order--wrapper {
      transition: 0.5s ease-in-out;

      &.active {
        background-color: $color-background-light;
        transition: 0.5s ease-in-out;
        transition-property: background-color;

        .account__order.flex-tr {
          border-bottom: 0;
        }
      }

      .order__fulfillment,
      .account__order .flex-td {
        font-size: 1rem;

        @include mq($until: laptop) {
          font-size: 12px;
        }
      }
    }

    .account__order-details--wrapper {
      border: solid 1px $color-secondary;
      border-top: none;
      transition: height 0.5s ease;

      &.collapsed {
        height: 0;
        overflow: hidden;
      }
    }

    .account__order-details {
      position: relative;
      display: flex;
      margin: 0;
      padding-top: 10px;
      border: none;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 40px);
        height: 1px;
        transform: translateX(-50%);
        background: $color-secondary;
      }

      .shipping-information {
        padding: 10px 0;
      }

      .title {
        display: inline-block;
        margin-bottom: 15px;
      }

      .price {
        margin-left: 5px;
        font-weight: unset;

        &--discounted {
          text-decoration: line-through;
          color: $color-grey;
        }
      }
    }

    .account__order-items {
      &.flex-table {
        flex: 1;
        margin: 0 10px;

        .flex-tr {
          border-color: $color-secondary;
          border-left: none;
          border-right: none;
          border-bottom: none;
          margin: 0 10px;
          padding: 10px 0;
          justify-content: flex-start;
        }

        .flex-td {
          &:first-child {
            padding-left: 0;
            width: 100px;
            margin-right: 5px;
          }

          &:nth-child(3) {
            width: auto;

            p {
              text-align: center;
            }
          }

          &:nth-child(4) {
            p {
              text-align: right;
            }
          }

          &:last-child {
            padding-right: 0;
          }

          .list-item-link {
            text-decoration: none;
            border-bottom: none;
          }
        }
      }

      .account__save-for-later {
        border: 0;
        font-family: $font-body;
        font-size: 1rem;
        font-weight: 300;
        background-color: transparent;
        margin-right: 25px;
        text-decoration: underline;
      }
    }

    span:not(.title) {
      font-weight: 500;
    }
  }

  .pagination {
    margin: 2rem 0 0;
    text-align: right;
  }

  .action--details {
    .icon {
      display: inline-block;
      height: 1.125rem;
      margin: 0 -0.375rem 0 0.375rem;
      transition: 0.5s ease;
      transition-property: transform;
      vertical-align: middle;
      width: 1.125rem;
    }

    &.active {
      .icon {
        transform: rotate(-180deg);
      }
    }
  }
}

.customer-orders__title {
  align-items: center;
  border-top: 1px solid $color-secondary;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  margin: 0 0 0.625rem;
  padding: 0.625rem 0;
}

.latest-order {
  font-size: 0.75rem;
  margin-bottom: 2em;

  &__details-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.625rem;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;

    .action {
      flex: 1 1 48%;
      margin-top: 0.625rem;
      font-size: 0.75rem;

      &:nth-child(odd):not(:last-child) {
        margin-right: 0.3125rem;
      }

      &:nth-child(even) {
        margin-left: 0.3125rem;
      }

      &:nth-child(odd):last-child {
        margin-bottom: 0;
      }
    }
  }

  .price {
    font-weight: 500;
  }

  .shipping-price.price--discounted {
    text-decoration: line-through;
    color: $color-grey;
  }

  .latest-order__content {
    padding: 0.625rem;
    background-color: $color-secondary;
    font-size: 0.75rem;

    span {
      font-weight: 500;
      text-transform: uppercase;
    }

    .latest-order__info,
    .latest-order__info--pickup {
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
        text-align: left;
        font-size: inherit;
      }

      .price {
        font-size: 1.125rem;
      }
    }

    .latest-order__info {
      align-items: center;
    }

    .latest-order__info--pickup {
      margin-top: 0.625rem;
    }

    .wrapper {
      display: flex;
      margin: 0;
    }
  }

  .latest-order__info {
    display: flex;
    margin: 1px 0;

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: auto;
      height: 50px;
      font-size: 0.75rem;
      flex: 1 0 auto;
    }

    .info-tile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 50px;
      background-color: $color-primary;
      height: 50px;
      margin-right: 1px;
      font-family: $font-heading;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
      color: $color-white;
      fill: $color-white;

      &.delivery-pickup {
        background-color: $color-accent;
        font-size: 0.6875rem;

        .icon {
          height: 1.875rem;
          margin: 0;
          width: 1.875rem;
        }

        span {
          margin-bottom: 5px;
        }
      }

      &.date {
        span {
          font-size: 1.25rem;
        }
      }

      &.time {
        span {
          font-size: 0.875rem;
          padding: 2px;

          @include mq($from: tablet) {
            font-size: 16px;
          }

          &:not(:last-child) {
            border-bottom: solid 1px $color-border;
          }
        }
      }
    }

    .get-direction {
      font-size: 12px;
    }
  }

  .latest-order__details {
    p,
    .subtitle,
    .title {
      text-align: left;
      font-size: 0.75rem;
    }

    .subtitle {
      font-weight: 700;
    }

    .wrapper {
      .action {
        font-size: 0.75rem;
        min-height: 0;
        min-width: 0;
        padding: 0.3125rem 0.625rem;

        &:first-child {
          margin-right: 0.3125rem;
        }
      }
    }

    .latest-order__item {
      display: flex;
      align-items: flex-start;
      padding: 15px 0;

      &:last-child {
        padding-bottom: 0;
      }

      img {
        margin-right: 0.625rem;
      }

      .title {
        font-size: 1rem;
        line-height: 1;
      }
    }

    .latest-order__item-details {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      margin: 0;

      .action {
        font-size: 0.75rem;
        margin: 0;
      }

      .line-item-details {
        flex: 1 1 auto;
        font-size: 0.875rem;
        margin: 0;
        margin-bottom: 0.625rem;
      }

      .price {
        font-size: 1.125rem;
      }

      .wrapper {
        align-items: center;
        justify-content: space-between;

        & > * {
          flex: 0 0 auto;
          margin-right: 0.3125rem;
        }
      }

      .title {
        line-height: 1.2;
      }
    }

    .latest-order__shipping-info {
      padding-top: 0.625rem;

      .wrapper {
        justify-content: space-between;
        margin: 0.625rem 0;
      }
    }
  }
}

.action--buttons {
  margin-bottom: 10px;
}

.delivery-time {
  display: none; // This will hidden until we have the delivery time
  flex-direction: column;

  &--title {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }

  &--date,
  &--time,
  &--order-tracking {
    font-weight: 300 !important;
  }

  &--order-tracking {
    cursor: pointer;
    text-decoration: underline;
  }
}

.account__order-button {
  @include mq($until: laptop) {
    margin-top: 2.4em;
  }
}

.account__order-details {
  &-address {
    span {
      font-weight: 300 !important;
    }
  }

  &-instructions,
  &-summary {
    span {
      font-weight: bold !important;
    }
  }

  &-summary {
    &-details {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      span {
        font-weight: 300 !important;
      }

      .title {
        font-weight: 500 !important;
      }

      .total {
        margin-top: 10px;
        font-weight: 500 !important;
      }
    }
  }

  .problems-questions {
    align-items: flex-end;
    flex: 1;
    display: flex;
    flex-direction: column;

    &-button {
      border: 1px solid $color-primary;
      background: none;
      color: $color-red;

      &:hover {
        color: $color-red;
      }
    }
  }

  .tile {
    @media screen and (min-width: 480px) {
      max-width: 10vw;
    }
  }
  .item-details {
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 0 !important;
      font-weight: bold !important;
    }

    span {
      &.total {
        font-weight: 500 !important;
      }

      font-weight: 300 !important;
    }

  }
  .add-to-basket {
      align-self: center;
      float: right;
      transform: scale(0.8, 0.8);
      -ms-transform: scale(0.8, 0.8);
      -webkit-transform: scale(0.8, 0.8);
      -o-transform: scale(0.8, 0.8);
      -moz-transform: scale(0.8, 0.8);

      @media screen and (max-width: 480px) {
        position: absolute;
        top: 5vh;
        right: 5vw;
      }
  }
}

/* stylelint-enable */
