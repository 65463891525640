@import '../vars/colors';

.action-button {
  background: linear-gradient(180deg, $color-grey-lighter 0%, $color-white 10.42%, $color-grey-light 97.4%);
  color: $color-primary;
  border: 1px solid $color-grey-light;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
  font-size: 0.75rem;
  min-width: 112px;
  padding: 14px;

  &:focus,
  &:hover,
  &:active {
    color: $color-primary;
  }
}