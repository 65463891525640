.customer-communication {
  max-width: 50rem;
  margin: 0 auto;

  .form__fieldset {
    border: 1px solid $color-secondary;
    padding: 1.25rem;
    margin: 2.5rem 0;
    text-align: left;
  }

  .form__legend {
    appearance: none;
    display: block;
    font-family: $font-heading;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.33333;
    text-transform: uppercase;
  }

  .form__field--text {
    max-width: 20rem;
  }

  .form__field--checkbox {
    label { /* stylelint-disable selector-max-combinators */
      font-family: $font-heading;
      font-size: .75rem;
      font-weight: 300;
      line-height: 1.33333;
      text-transform: uppercase;
    }
  }

  .action {
    margin: 0 .625rem 1.25rem;
  }
}
