.how-it-works-delivery {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 0 10px 50px;

  @include mq($until: hiw-width) {
    background: darken($color-primary, 10%);
  }

  @include mq($from: hiw-width) {
    padding: 100px 20px 100px 355px;
  }

  &__container {
    position: relative;
    overflow: hidden;
  }

  &__header {
    position: relative;
    padding: 50px 10px;
    background: darken($color-primary, 10%);
    color: $color-white;

    @include mq($until: hiw-width) {
      text-align: center;
    }

    @include mq($from: hiw-width) {
      display: flex;
      align-items: flex-end;
      padding: 25px 50px;
    }
  }

  &__title {
    margin: 0;
    line-height: 1.785;

    @include mq($until: hiw-width) {
      font-size: 20px;
    }

    @include mq($from: hiw-width) {
      font-size: 24px;
      margin: 0 20px 0 0;
    }

    &--mobile {
      font-size: 26px;

      @include mq($from: hiw-width) {
        display: none;
      }
    }
  }

  &__zip {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    font-family: $font-heading;
    font-weight: 700;

    @include mq($until: hiw-width) {
      margin-top: 20px;
    }
  }

  &__zip-code {
    vertical-align: middle;
    padding: 10px 30px;
    border-bottom: 1px solid $color-white;
    font-size: 14px;
    font-family: $font-heading;
    font-weight: 700;
    color: $color-accent;
    background: $color-white;

    &:hover {
      cursor: pointer;
    }
  }

  &__zip-button {
    border: 1px solid $color-white;
    vertical-align: middle;
    border-color: $color-white;
    font-family: $font-heading;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: $color-white;
    background: none;
    padding: 10px;
    text-transform: uppercase;
    height: auto;
    min-width: 0;
    min-height: auto;
    transition: 0.2s;

    &:hover {
      color: $color-primary;
      background: $color-secondary-lighter;
      border-color: $color-white;
    }
  }

  &__content {
    position: relative;

    @include mq($from: hiw-width) {
      display: flex;
      box-shadow: 0 10px 20px 0 rgba($color-black, 0.5);
      min-height: 580px;
    }
  }

  &__section {
    position: relative;
    z-index: 2;
    overflow: hidden;
    padding-top: 30px;
    transition: z-index 0.2s;

    &.show-slide {
      z-index: 4;
    }

    @include mq($until: hiw-width) {
      display: flex;
      flex-direction: column;
      text-align: center;
      box-shadow: 0 10px 20px 0 rgba($color-black, 0.5);
    }

    @include mq($from: hiw-width) {
      padding: 50px;
      width: 50%;
      box-sizing: border-box;
    }

    &.how-it-works-delivery__section--outside-florida {
      display: flex;
      padding: 0;
      width: 100%;
    }
  }

  &__map-close {
    position: absolute;
    top: 10px;
    right: 10px;
    fill: $color-white;
    height: 2.125rem;
    width: 2.125rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__map-content {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    padding: 50px;
    color: $color-white;

    a {
      display: block;
      margin-top: 30px;
    }
  }

  &__map-title {
    font-size: 16px;

    strong,
    &--highlight {
      font-family: $font-heading;
      font-weight: 700;
      text-transform: uppercase;
    }

    &--highlight {
      display: block;
      color: $color-accent;
    }
  }

  @include mq($until: hiw-width) {
    &__how {
      padding: 50px 15px;
    }

    &__how-image-container {
      width: 50%;
      margin: 0 auto 1.5rem;

      .responsive-image {
        margin: 0 auto;
      }
    }

    &__how-image {
      width: 100%;
    }

    &__how-content {
      padding-bottom: 30px;

      h4 {
        margin: 20px 0;
      }
    }
  }

  @include mq($from: hiw-width) {
    &__how {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__how-image-container {
      width: 30%;
      max-width: 135px;
      max-height: 135px;
      margin-right: 40px;
    }

    &__how-image {
      width: 100%;
    }

    &__how-content {
      width: calc(100% - 135px);
    }
  }

  &__subheading,
  &__details,
  &__note {
    @include mq($until: hiw-width) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &__subheading,
  &__details,
  &__note,
  &__button {
    @include mq($from: hiw-width) {
      max-width: 325px;
    }
  }

  &__times {
    display: grid;
    grid-template-columns: 80px 160px;
    grid-column-gap: 10px;

    @include mq($until: hiw-width) {
      justify-content: center;
    }

    &--title {
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__subheading-icon {
    display: block;
    height: 60px;
    width: 60px;

    @include mq($until: hiw-width) {
      margin: 0 auto;
    }
  }

  &__type {
    margin-bottom: 0;
  }

  &__subtitle {
    margin-top: 0;

    strong {
      font-weight: 700;
    }
  }

  &__details {
    margin: 20px 0 30px;

    p {
      margin: 10px 0;
    }

    .store-name {
      text-decoration: underline;
    }
  }

  &__mobile-details-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
  }

  &__big-icon {
    width: 30px;
    height: 30px;
  }

  &__accordion-button {
    cursor: pointer;

    svg {
      width: 1rem;
      height: 1rem;
      fill: inherit;
      vertical-align: middle;
    }
  }

  svg {
    display: inline-block;
    transition: 0.2s;
  }

  &__accordion-detail {
    &--map {
      position: relative;
    }
  }

  &__note {
    padding-bottom: 20px;
    font-size: 0.875rem;

    @include mq($from: hiw-width) {
      padding-bottom: 100px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    border: none;
    background: none;

    @include mq($from: hiw-width) {
      position: absolute;
      bottom: 50px;
    }

    &:hover,
    &:focus {
      background: none;
    }

    &-border {
      display: inline-block;
      height: 2.125rem;
      width: 2.125rem;
      margin-right: 20px;
      padding: 5px;
      border: 1px solid $color-border-light;
    }

    svg {
      transition: $transition;
      transform: rotate(45deg);
    }
  }

  &__small-link {
    &--underline {
      text-decoration: underline;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &--desktop {
    @include mq($until: hiw-width) {
      display: none !important;
    }
  }

  &--mobile {
    @include mq($from: hiw-width) {
      display: none !important;
    }
  }

  p {
    margin: 0;

    @include mq($from: hiw-width) {
      text-align: left;
    }
  }

  h2,
  h4 {
    margin-top: 0;
    padding: 0;
  }
}

.how-it-works {
  .active {
    svg { /* stylelint-disable-line selector-max-combinators */
      transform: rotate(180deg);
    }
  }
}

.how-it-works-delivery__button-text {
  font-family: $font-heading;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.hiw-delivery__background-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.how-it-works-delivery__section--pickup {
  background: $color-primary;
  color: $color-white;

  svg {
    fill: $color-white;
  }

  .how-it-works-delivery__small-link {
    color: $color-white;
  }

  .how-it-works-delivery__mobile-details {
    border-top: 1px solid $color-white;
  }
}

.how-it-works-delivery__button--pickup {
  color: $color-white;

  svg {
    fill: $color-white;
  }
}

.how-it-works-delivery__button--pickup:hover {
  .how-it-works-delivery__button-border {
    background: $color-white;
  }

  svg {
    fill: $color-primary;
  }
}

.how-it-works-delivery__section--delivery {
  right: 0;
  background: $color-secondary-lighter;

  @include mq($until: hiw-width) {
    margin-top: 20px;
  }

  .how-it-works-delivery__button,
  .how-it-works-delivery__big-icon {
    color: $color-primary;

    &-border {
      background: $color-secondary-lighter;
    }
  }

  .how-it-works-delivery__mobile-details {
    border-top: 1px solid $color-black;
  }
}

.how-it-works-delivery__button--delivery:hover {
  .how-it-works-delivery__button-border {
    background: $color-primary;
  }

  svg {
    fill: $color-white;
  }
}

.how-it-works-delivery__section--flyout {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 50%;
  height: 100%;
  transition: z-index 0.2s, transform 0.2s;

  &.show-slide {
    z-index: 3;
  }
}

.how-it-works-delivery__section--map {
  right: 0;
  padding: 0;
  background-color: #2b2724;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &.show-slide {
    transform: translateX(-100%);
  }
}

.how-it-works-delivery__section--how {
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 50px;
  border-top: 1px solid $color-primary;
  background: darken($color-primary, 10%);
  color: $color-white;

  &.show-slide {
    transform: translateX(100%);
  }
}

.how-it-works-delivery__button:hover svg {
  fill: $color-accent;
}

.outside-florida-map {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  min-height: 378px;
  background-color: #2b2724;
  color: #ffffff;

  @include mq($from: hiw-width) {
    width: 50%;
    min-height: 575px;
  }

  .section__background-img {
    position: absolute;
    width: 100%;
  }

  &__content {
    z-index: 2;
    max-width: 300px;
    margin: 0 auto;

    h2 {
      margin-bottom: 10px;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0.05px;
    }

    span {
      font-family: $font-heading;
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.04px;
      text-transform: uppercase;
    }
  }
}

.outside-florida-form {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #ffffff;

  @include mq($from: hiw-width) {
    width: 50%;
  }

  &__content {
    max-width: 300px;
    margin: 0 auto;

    h3 {
      margin-bottom: 0;
      padding: 0;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.04px;
    }

    label {
      font-size: 16px;
      color: #9e9e9e;
    }

    input {
      background: none;
    }

    .input-group {
      margin-bottom: 10px;
    }

    button {
      font-size: 12px;
      min-width: 144px;
      min-height: auto;
      padding: 18px 15px;
      margin-top: 20px;
    }
  }
}

.how-it-works-delivery__mobile-slider {
  .tns-outer {
    position: relative;
  }

  .tns-slider {
    display: inline-flex;
  }

  .tns-controls {
    position: absolute;
    top: 26vw;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;

    /* stylelint-disable selector-max-combinators */

    button {
      background: none;
      border: none;
      min-width: auto;
      font-family: $font-heading;
      color: $color-white;
      text-transform: uppercase;
      width: 4rem;
      height: 4rem;

      &:focus { /* stylelint-disable-line selector-max-specificity */
        background: none;
      }
    }

    /* stylelint-enable selector-max-combinators */
  }

  .tns-visually-hidden {
    display: none;
  }

  .tns-nav {
    padding-bottom: 2rem;

    /* stylelint-disable selector-max-combinators */

    button {
      height: 0.875rem;
      width: 0.875rem;
      padding: 0;
      min-width: auto;
      min-height: auto;
      border: 1px solid $color-white;
      border-radius: 50%;
      margin: 0 0.375rem;
    }
    /* stylelint-enable selector-max-combinators */
  }

  .tns-nav-active {
    background: $color-accent;
  }
}

.text-input {
  ~ label {
    position: absolute;
    top: 50%;
    left: 0;
  }

  &:focus ~ label,
  &.has-content ~ label {
    top: 5px;
    font-size: 11px;
    transition: 0.2s ease-in-out;
  }
}

.input-group {
  position: relative;
  padding-top: 1.25rem;
  margin-bottom: 1.25rem;

  input,
  select {
    font-size: inherit;
    padding: 3px 0;
    width: 100%;
    border: none;
    border-bottom: solid 2px $color-primary;
  }

  label {
    transition: 0.2s ease-in-out;
    text-transform: none;
    letter-spacing: normal;
  }

  .select-wrapper {
    &::after {
      margin: 0;
    }
  }
}

.select-wrapper {
  label {
    top: -1.25rem;
    font-size: small;
  }
}
