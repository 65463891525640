img,
svg,
audio,
video,
iframe {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.responsive-image {
  position: relative;
  display: block;
  width: 100%;
  max-height: var(--max-height);
  max-width: var(--max-width);
}

.responsive-image::before {
  content: '';
  display: block;
  padding-bottom: var(--aspect-ratio);
}

.responsive-image__image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: var(--object-fit);
  object-position: var(--object-position);
}
