
.checkout-customer-address {
  border: solid 1px $color-secondary;
  background: $color-grey-light;
  display: grid;
  grid-gap: 0.625em;
  grid-template-rows: auto auto 2.5em;
  justify-content: center;
  padding: 1em;
  text-align: center;

  .action {
    height: 2.9167em;
    margin: 0;
    padding: 0 1em;
  }
}

.checkout-customer-address__name {
  font-family: $font-heading;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

.checkout-customer-address__details {
  font-size: 0.75em;
  margin: 0;
}
