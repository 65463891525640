// @import '../common';
@import './how-it-works/mixins';

$transition-slow: 0.5s ease;

.hiw__background-img {
  position: absolute;

  &--alt {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
  }
}

.section__background-img {
  &--mb {
    @include mq($from: tablet-w) {
      display: none;
    }
  }
}

.js-how-it-works {
  *,
  *::before,
  *::after {
    box-sizing: content-box;
  }

  li {
    list-style: none;
  }
}

.hiw-button {
  @include buttonReset;
  background: $color-primary;
  color: $color-white;
  font-family: $font-heading;
  font-size: 12px;
  font-weight: 700;
  min-width: 160px;
  padding: 13px 10px;
  line-height: 1.17;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.2s ease;

  @include mq($from: laptop) {
    font-size: 16px;
    padding: 15px 10px;
  }

  &:focus,
  &:hover {
    background-color: darken($color-primary, 10%);
    color: $color-white;
  }

  &--light-red {
    background-color: transparent;
    border: 1px solid $color-white;
    color: $color-accent;

    &:focus,
    &:hover {
      background-color: $color-white;
      color: $color-accent;
    }
  }
}

@import './how-it-works/scroll';
@import './how-it-works/hero';
@import './how-it-works/navigation';
@import './how-it-works/packaging/insulated-delivery';
@import './how-it-works/packaging/packaging';
@import './how-it-works/packaging/temperatures';
@import './how-it-works/enjoy';
@import './how-it-works/membership';
@import './how-it-works/questions';
@import './how-it-works/shopper';
@import './how-it-works/delivery';
