.customer-welcome {
  background: $color-background-dark;
  color: $color-text-light;
  width: 100%;
  padding: 3.125rem 0 4.375rem;
  text-align: center;

  @include mq($from: tablet-w) {
    max-width: 90rem;
    margin: 0 auto;
  }
}

.customer-welcome__header,
.customer-welcome__main,
.customer-welcome__footer {
  @include wrapper(75rem);
  padding: 1.25rem .625rem;
}

.customer-welcome__icon {
  background: $color-background-lightest;
  border-radius: 100%;
  display: block;
  height: 2rem;
  margin: 0 auto;
  width: 2rem;
}

.customer-welcome__title {
  font-size: 1.875rem;
  line-height: 1.33333;
  margin: .33333em;
}

.customer-welcome__subtitle {
  max-width: 30rem;
  margin: 2em auto .75em;
}

.customer-welcome__tabs {
  align-items: stretch;
  display: flex;
  max-width: 37.5rem;
  margin: 0 auto;
  cursor: pointer;

  @include mq($from: tablet-w) {
    display: none;
  }
}

.customer-welcome__tab {
  @include buttonReset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 1 50%;
  font-family: $font-button;
  font-size: .875rem;
  font-weight: 700;
  line-height: 2;
  padding: .625rem;
  text-decoration: none;
  text-transform: uppercase;

  &,
  &:focus,
  &:hover {
    color: $color-text-light;
  }

  &--active {
    background-color: $color-background-lightest !important;

    &,
    &:focus,
    &:hover {
      color: $color-text;
    }
  }
}

.customer-welcome__forms {
  @include mq($until: tablet-w) {
    max-width: 37.5rem;
    margin: 0 auto;
  }

  @include mq($from: tablet-w) {
    display: flex;
    justify-content: space-around;
  }
}

.customer-welcome__form {
  background: $color-background-lightest;
  color: $color-text;
  padding: 4.375rem 1.25rem 1.875rem;

  @include mq($from: tablet-w) {
    flex: 0 1 48%;
  }

  &:not(&--active) {
    @include mq($until: tablet-w) {
      display: none;
    }
  }

  .form__link {
    font-size: .75rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .form__actions {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    column-gap: 10px;
  }
}

.customer-welcome__footer {
  .action {
    font-size: .875rem;
    margin-top: 1.875rem;
  }
}
