@import '../vars/layout';

%heading {
  line-height: 1.3;
  font-weight: 700;
  font-family: $font-heading;
  text-transform: uppercase;
}

%h1 {
  @extend %heading;
  line-height: 1.15;
  font-size: 1.625rem;

  @include mq($from: tablet) {
    font-size: 3.75rem;
  }
}

%h2 {
  @extend %heading;
  font-size: 1.25em;

  @include mq($from: tablet) {
    font-size: 1.875rem;
  }
}

%h3 {
  @extend %heading;
  font-size: 1.125rem;
}

%h4 {
  @extend %heading;
  font-size: 0.875rem;
}

%h5 {
  @extend %heading;
  font-size: 0.75rem;
}

%h6 {
  @extend %heading;
  font-size: 0.625rem;
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}
