
.checkout-steps {
  box-sizing: border-box;
  display: grid;
  border-bottom: none;
  flex: 1 1 auto;
  grid-gap: 0.5em;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  padding: 0;
  margin: 0.5em 0 0;
  width: 100%;
}

.checkout-steps__step {
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: $color-text-secondary;
  font-size: 0.5625em;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  transition: opacity 0.3s ease;

  &--current {
    color: $color-text;
    opacity: 1;

    &::before {
      content: '';
      background-color: $color-accent;
    }
  }

  &--disabled {
    cursor: default;
  }
}

.checkout-steps__button {
  width: 100%;
  margin: 0;
  padding: 0.444em 0 0;
  border-top: solid $color-text-secondary;
  border-width: 1em 0 0;
  font: inherit;
  line-height: normal;
  text-align: left;
  text-transform: inherit;

  .checkout-steps__step--current & {
    border-top-color: $color-accent;
  }

  .checkout-steps__step--completed & {
    &::after {
      content: '\2713';
      margin-left: 0.444em;
    }
  }
}
