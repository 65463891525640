@import "styles/vars/colors";

.container{
  justify-content: space-between;
  font-size: 1rem;
  max-width: 25rem;
  
  > div:first-child {
    min-width: fit-content;
    font-size: 1.75rem;
  }
  > div:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
    max-height: 6.5rem;

    @media (max-width: 60em) {
      width: 100%;
      font-size: 1rem;
      -webkit-line-clamp: 3; /* number of lines to show */
      max-height: 5rem;
    }
  }
  > div:last-child {
    position: relative;
    padding: 4px 0 0 0px;
    min-width: 2rem;
    margin-left: 10px;
  }
  
  @media (max-width: 60em) {
    width: 100%;
    font-size: 1.15rem;
  }
}

.variant--outlined{
  background-color: $color-grey-lighter;
}