.how-it-works-hero {
  &__outer {
    @include mq($from: hiw-width) {
      margin-top: -260px;
    }
  }

  &__image {
    position: relative;
    height: 75vh;
  }

  &__background {
    position: relative;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    max-width: none !important; // needed to override snippet inline style
    max-height: none;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transform-origin: center center;

    @include transition($transition-slow, opacity);

    @include mq($from: tablet-w) {
      display: block;
    }

    .responsive-image__image {
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 90%;

    @include mq($from: tablet) {
      text-align: center;
    }
  }

  &__heading {
    font-size: 26px;
    color: $color-white;
    line-height: 1.17;
    margin-top: 0;
    letter-spacing: 0.05px;
    text-shadow: 1px 0 10px rgba(0, 0, 0, 0.8);

    @include mq($from: tablet) {
      font-size: 30px;
    }

    @include mq($from: hiw-width) {
      font-size: 60px;
      margin-bottom: 40px;
      text-shadow: 10px 8px 18px rgba(0, 0, 0, 0.5);
    }
  }

  &__button {
    font-size: 14px;
    max-width: 16px;
    margin: 10px auto;
    display: none;

    @include mq($from: tablet) {
      display: block;
    }
  }

  &__app {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    display: none;
    width: 400px;
    max-width: 27.5%;

    @include mq($from: laptop) {
      display: block;
    }

    &-image {
      max-width: 100%;
    }

    &-content {
      position: absolute;
      top: 50%;
      text-align: right;
    }

    &-title {
      font-family: $font-heading;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &-logo {
      display: block;
      max-width: 130px;
      margin-top: 20px;
    }
  }
}
