
.delivery-problems {
  box-sizing: border-box;
  margin: auto;
  max-width: 23em;
  text-align: center;
}

.delivery-problems__heading,
.delivery-problems__amount-remaining {
  font-size: 1.125em;
  line-height: normal;
  margin-bottom: 0.5em;
  padding: 0;
  text-align: inherit;
}

.delivery-problems__amount-remaining {
  margin-bottom: 2em;

  .amount {
    color: $color-red;
  }
}

.delivery-problems__controls {
  margin: 4em 1em 1em;

  .action {
    display: block;
    font-size: 0.875em;
    margin: 0.7143em 0;
    padding: 1em;
    width: 100%;
  }
}
