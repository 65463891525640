@mixin how_it_works_title {
  font-size: 26px;
  color: $color-white;
  text-shadow: 1px 0 10px rgba(0, 0, 0, 0.8);
  line-height: 1.15;
  padding: 0;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@mixin active_nav_item {
  color: $color-accent;

  &::before {
    background-color: $color-accent;
    border-color: $color-accent;
  }
}

@mixin transition($transitions, $properties...) {
  $unfoldedTransitions: ();

  @each $property in $properties {
    $unfoldedTransitions: append($unfoldedTransitions, append($property, $transitions), comma);
  }
  transition: $unfoldedTransitions;
}
