

.order-summary {
  h2 {
    font-size: 1.125em;
    font-weight: 500;
    margin: 1em;

    @include mq($until: tablet) {
      text-align: center;
    }
  }
}

.order-summary__section__content {
  @include hide-scrollbar;

  @media (min-width: 1000px) {
    padding: 1.25em 0;
  }
}

.order-summary__section--product-list {
  @media (min-width: 1000px) {
    max-height: 30vh;
    min-height: 11.4em;
    overflow: auto;
  }

  .product-table {
    font-size: 0.813em;

    @include mq($from: mobile-w) {
      font-size: 1em;
    }

    @media (min-width: 1000px) {
      margin: 1.25em 0;
    }
  }
}
