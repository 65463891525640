

/* --- How to customize widgets? ---

1. Preview a widget (button at top).
2. Inspect the elements you want to change, and change them.
3. Copy the css code back here.
4. Preview, and save when you are satisfied with the results.

/* ---------------------------------- */

/* stylelint-disable */
@import "../vars/colors";

.product__reviews {
  padding: 1.25rem;
}

.modal__content {
  .yotpo {
    padding: 0 0.75rem !important;
  }
}

.yotpo {
  * {
    // my apologies..
    font-family: $font-body !important;
  }

  .yotpo-label-container {
    display: none;
  }

  .yotpo-logo-link-new {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .yotpo-logo-title {
    color: $color-primary !important;
    font-family: $font-body;
    font-weight: 400;
  }

  .yotpo-icon-yotpo-logo {
    color: $color-accent !important;
  }

  .yotpo-regular-box {
    padding: 1.25rem 0;
    margin: 0 !important;
  }

  .new-yotpo-small-box {
    @include mq($until: tablet-w) {
      margin: 35px 0 0;
    }
  }

  .yotpo-display-wrapper {
    @include mq($from:tablet-w) {
      display: inline-block !important;
    }
  }

  .bottom-line-items {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding-inline-end: 0 !important;
    min-height: 7.75rem !important;

    @include mq($until: tablet-w) {
      width: 100% !important;
    }

    .rating-stars-container {
      float: none;
      margin-left: 0;
      margin-right: 0.625rem;

      .rating-star {
        height: 1.5rem;
      }
    }

    .reviews-qa-label  {
      font-size: 1rem !important;
      font-family: $font-body !important;
      color: $color-primary;
      width: fit-content;
    }

    .yotpo-icon-star {
      font-size: 1.5rem !important;
    }
  }

  .yotpo-star-distribution-wrapper {
    width: calc(100% - 51px) !important;
    height: auto !important;
    margin-left: auto !important;
    float: none !important;

    @include mq($until: tablet-w) {
      margin-right: auto !important;
    }

    @include mq($from: tablet-w) {
      width: 180px !important;
      margin-top: 0.625rem !important;
    }
  }

  .yotpo-regular-box {
    padding: 0 !important;

    &.yotpo-review {
      padding: 1rem 0 2rem !important;
    }
  }

  .yotpo-distribution-row {
    width: 100% !important;
  }

  .yotpo-star-distribution-bar {
    width: 90% !important;

    @include mq($from: tablet-w) {
      width: 100px !important;
    }
  }

  .yotpo-star-distribution-score-value {
    color: $color-primary !important;
  }

  .yotpo-star-distribution-bar-score {
    background-color: $color-accent !important;
  }

  .yotpo-star-distribution-bar,
  .yotpo-star-distribution-bar-score {
    height: 0.625rem !important;
  }

  .write-question-review-buttons-container {
    padding-top: 20px !important;
    flex-direction: row !important;

    @include mq($from: tablet-w) {
      display: inline-block !important;
      padding-top: 0 !important;
      transform: translateY(50%);

      .yotpo-default-button {
        display: block !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 1rem !important;
      }
    }

    .write-question-review-button {
      padding: 0.875rem !important;
      height: unset !important;

      .write-question-review-button-text {
        font-family: $font-button !important;
        font-weight: bold !important;
        font-size: 12px !important;
        text-transform: uppercase;
      }
    }

    .write-review-button {
      background: linear-gradient(180deg, #64615D 0%, #3D3935 6.25%, #181818 100%) !important;
      border: 1px solid #000000 !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
      border-radius: 4px !important;

      &:hover {
        background: linear-gradient(180deg, #64615D 0%, #3D3935 6.25%, #181818 100%) !important;
        color: $color-white !important;
      }
    }

    .write-question-button {
      background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 10.42%, #ECECEC 97.4%) !important;
      border: 1px solid #E0E0E0 !important;
      box-sizing: border-box !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06) !important;
      border-radius: 4px !important;

      &:hover {
        background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 10.42%, #ECECEC 97.4%) !important;
        color: $color-primary !important;
      }
    }

    .yotpo-review {
      .yotpo-user-name,
      .yotpo-main {
        color: $color-text !important;
      }

      .content-title {
        font-size: 1rem !important;
        font-weight: 700 !important;
      }
    }
  }

  .yotpo-question {
    padding: 1rem 0 2rem !important;

    .content-question {
      color: $color-text !important;
      padding-top: 0.5rem !important;

      a {
        word-break: break-all;
      }
    }

    .yotpo-comments-box {
      border-left-color: $color-secondary !important;
      margin-left: 1.25rem !important;
    }

    .yotpo-comment-box {
      margin-left: 1.25rem !important;

      @include mq($from: tablet-w) {
        margin-left: 3.1875rem !important;
      }
    }
  }

  .avg-score {
    display: none;
  }

  .reviews-qa-labels-container {
    position: relative !important;
    float: none !important;
    padding-top: 20px !important;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-secondary;
    display: inherit;
    height: 3rem !important;

    @include mq($until: tablet-w) {
      margin-left: 0 !important;
    }

    .reviews-qa-label {
      top: 0 !important;
      width: auto !important;
      height: auto !important;

      @include mq($until: tablet-w) {
        padding-top: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  .bottom-line-items {
    padding-inline-end: 0 !important;
    padding-inline-start: 0 !important;
    float: none !important;
    margin-bottom: 0 !important;
  }

  .bottom-line-only-container {
    display: block !important;
    width: auto !important;
  }

  .form-input-error {
    display: block !important;
    font-size: 0.725rem !important;
  }
}

.yotpo-main-widget {
  max-width: 43rem;
}

.main-widget {
  &:not(.yotpo-no-reviews) {
    @include mq($from: tablet-w) {
      &:before {
        content: ' ';
        display: block;
        border-top: 1px solid $color-secondary;
        top: 2rem;
        position: relative;
      }
    }
  }

  .bottom-line-items {
    margin: 0 !important;
    padding: 0 !important;
    float: none !important;
    display: block !important;
  }
}

.bottom-line-items-container-desktop {
  height: 100% !important;

  @include mq($from: tablet-w) {
    border-top: none !important;
    border-bottom: none !important;
    padding: 0 !important;
    position: unset;
    padding: 0;
    width: 100%;
    margin: 0 !important;
    display: grid;

    .border-line-items-wrapper {
      align-self: center;
    }

    .bottom-line-items,
    .bottom-line-only-container,
    .reviews-qa-labels-container  {
      height: 100% !important;
    }

    .reviews-qa-labels-container {
      height: 100%;
      width: 14.75rem !important;
      border-bottom: none !important;
      border-right: 1px solid $color-secondary !important;

      @include mq($from: tablet-w) {
        margin-right: 6rem !important;
      }
    }
  }

  @include mq($until: tablet-w) {
    .bottom-line-items {
      height: 100% !important;
    }

    .yotpo-star-distribution-wrapper {
      padding-right: 0 !important;
    }
  }
}

.bottom-line-items-container-mobile {
  height: unset !important;
  border-bottom: none !important;
}

.bottom-line-items-wrapper {
  flex-direction: row !important;
  width: 100% !important;
  position: unset !important;
  place-content: flex-end;
  transform: unset !important;

  @include mq($from: tablet-w) {
    display: flex !important;
  }
}

.avg-score,
.yotpo-reviews-filters,
.yotpo-filter-stars,
.yotpo-icon-dialog,
.yotpo-icon-write-no-frame,
.yotpo-reviews-header {
  display: none !important
}

.write-question-review-buttons-container {
  height: 100%;
  float: unset !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
  place-items: flex-end;
  place-content: center;
}

.write-question-review-button-text {
  color: $color-text !important;
}

.write-review-button span {
  color: $color-white !important;
}

.yotpo-default-button:not(.yotpo-icon-btn) {
  width: 135px;
  height: 105px;
}

.yotpo-star-distribution-score-value {
  font-family: $font-body !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.yotpo-icon-profile {
  background: #3D3935 !important;
  border-radius: 50% 0% 50% 50% !important;

  @include mq($until: tablet-w) {
    width: 2.25rem !important;
    height: 2.25rem !important;
    margin-right: 0.875rem !important;
  }

  @include mq($from: tablet-w) {
    margin-right: 3rem !important;
  }
}

.yotpo-icon-circle-checkmark {
  background: $color-red !important;
  border-radius: 50% 0% 50% 50% !important;
  position: absolute !important;
  left: 29px !important;
  top: 0px !important;
  font-size: 10px !important;
  color: #FFFFFF !important;
  border: solid 3px $color-red !important;

  &::before {
    content: "\e60a" !important;
  }
}

.yotpo-user-letter {
  font-size: 1.125rem !important;
  font-family: $font-button !important;
  font-weight: 700 !important;

  @include mq($until: tablet-w) {
    top: 0.625rem !important;
  }

  @include mq($from: tablet-w) {
    font-size: 26px !important;
  }
}

.yotpo-store-owner {
  .yotpo-icon-profile {
    background: $color-accent !important;
  }

  img.yotpo-store-avatar {
    width: 1.25rem !important;
    height: 1.25rem !important;
    margin-left: 0.575rem;
    margin-top: 0.5rem;

    @include mq($from: tablet-w) {
      width: 1.625rem !important;
      height: 1.625rem !important;
      margin-left: 0.625rem;
      margin-top: 0.5rem;
    }
  }

  .yotpo-icon-circle-checkmark {
    display: none !important;
  }
}

.yotpo-icon-circle-checkmark {
  @include mq($until: tablet-w) {
    left: 1.375rem !important;
    width: 0.75rem !important;
    height: 0.75rem !important;
    font-size: 0.5rem !important;
  }
}

.yotpo-default-button {
  background: $color-accent !important;
  font-weight: 700 !important;
}

.yotpo-user-name,
.content-review,
.y-label,
.yotpo-review-date,
.vote-btn {
  font-family: $font-body !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #3D3935 !important;
}

.yotpo-user-title {
  font-family: $font-body !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $color-red !important;
}

.content-title {
  font-family: $font-body !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  color: #3D3935 !important;
  padding-bottom: 0 !important;
}

.yotpo-review,
.yotpo-question {
  .yotpo-main,
  .yotpo-footer {
    @include mq($until: tablet-w) {
      margin-left: 0 !important;
    }

    @include mq($from: tablet-w) {
      margin-left: 5.875rem !important;
    }
  }
}

.yotpo-no-reviews {
  width: 100%;

  .yotpo-first-review {
    margin-top: 1rem !important;
  }

  .write-question-review-buttons-container {
    transform: translateY(0) !important;
  }

  .yotpo-questions {
    .write-first-question-button {
      margin-left: 0 !important;
    }
  }

  .yotpo-first-review-stars {
    display: none;
  }

  .reviews-qa-label {
    left: 0 !important;
  }
}

.write-first-review-button,
.write-first-question-button {
  width: 12.5rem !important;
}

.yotpo-nav-tab.yotpo-active span {
  color: $color-accent !important;
}

.modal__content {
  .yotpo-no-reviews {
    max-width: 35rem;
    margin: 0 auto;
    padding-bottom: 1rem;
    position: relative;
    display: block !important;

    .bottom-line-items-container-mobile {
      padding: 1.5rem 0 0 !important;
    }

    .bottom-line-items-container-desktop .bottom-line-items-wrapper .bottom-line-only .bottom-line-only-container {
      float: none !important;
    }

    .write-question-review-buttons-container {
      @include mq($from: tablet-w) {
        transform: translateY(50%) !important;
      }
    }
  }
}

.yotpo-pager .yotpo-page-element {
  color: $color-red !important;
  border-color: $color-red !important;
  
  &:hover {
    border-color: $color-red !important;
    color: $color-red !important;
  }
}

.yotpo-or {
  font-weight: 400 !important;
}
/* stylelint-enable */
