@import '../vars/colors';

body {
  background: $color-white;
  color: $color-text;
  font-family: $font-body;
  font-weight: 300;
  line-height: 1.4;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a {
  background: transparent;
  color: $color-link;

  &:focus,
  &:hover {
    color: $color-link-hover;
  }

  &:active {
    color: $color-link-active;
  }
}

ol,
ul {
  /* stylelint-disable-line block-no-empty */
}

li {
  /* stylelint-disable-line block-no-empty */
}

dl {
  /* stylelint-disable-line block-no-empty */
}

dt {
  /* stylelint-disable-line block-no-empty */
}

dd {
  /* stylelint-disable-line block-no-empty */
}