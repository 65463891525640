
.inventory-problems {
  box-sizing: border-box;
  margin: auto;
  text-align: center;
}

.inventory-problems__heading {
  font-size: 1.125em;
  line-height: normal;
  margin-bottom: 1em;
  padding: 0;
  text-align: inherit;
}

.inventory-problems__controls {
  @include mq($from: tablet) {
    margin: 1em 1.875em 0;
  }

  &-action {
    display: block;
    font-size: 0.875em;
    margin: 0.7143em 0;
    padding: 1em;
    width: 100%;
  }
}

.inventory-problems__items {
  margin: 1.875em 0;
  max-height: 30vh;
  overflow: auto;

  &-item {
    display: grid;
    grid-template:
        "image title"
        "image details"
        "image details";
    grid-gap: 0 0.625em;
    margin: 1em auto;
    max-width: 18.75em;
  }

  &-item-image {
    grid-area: image;
    height: 4.5em;
    width: 4.5em;
  }

  &-item-title,
  &-item-details {
    line-height: 1;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  &-item-title {
    font-size: 1em;
    grid-area: title;
    line-height: 1.13;
  }

  &-item-details {
    font-size: 0.875em;
    grid-area: details;
    line-height: normal;
  }

  &-item-subtitle {
    display: block;
    font-style: italic;
  }

  &-item-price {
    display: block;
    font-weight: 500;
    color: $color-greyish-brown;
  }
}
