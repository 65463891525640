@import '../vars/layout';
@import '../vars/colors';

html {
  box-sizing: border-box;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

.layout {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  &--no-scroll {
    overflow: hidden;
  }
}

.layout__footer {
  background: $color-background;
}

.layout__main {
  flex-grow: 1;
  min-height: 80vh;
  @include mq($from: tablet-w) {
    min-height: 50vh;
  }
}

.layout__grid {
  width: 100%;
  max-width: 90rem;
  min-height: 50vh;
  margin: 0 auto;
  

  @include mq($from: tablet-w) {
    display: grid;
    grid-template:
    'sidebar main' 1fr
    'sidebar footer'
    / 22.125em auto;
  }
  @media (max-width: 1500px) {
    padding: 0 1.25rem;
  }
}

.layout__sidebar {
  grid-area: sidebar;
  min-width: 100%;

  @include mq($from: tablet-w) {
    min-height: 44rem;
    background: $color-background-sidebar;
    color: $color-text-light;
    padding: 3rem 1.25rem;
  }
}

.layout__main {
  display: flex;
  position: relative;
  grid-area: main;
  flex-direction: column;
}

.layout__footer {
  grid-area: footer;
}

.account {
  display: contents;
}

.no-scroll {
  overflow: hidden;
}

.footer-container {
  padding: 0;
}
