// Basic colors
$color-black: #000000;
$color-grey: #9e9e9e;
$color-trey: #919191;
$color-grey-medium: #c6c6c6;
$color-grey-dark: #333333;
$color-greyish-brown: #3d3935;
$color-very-light-shade-grey: #e6e6e6;
$color-light-shade-grey: #f6f6f6;
$color-greyish-brown-dark: #22201d;
$color-grey-light: #e0e0e0;
$color-grey-lighter: #fafafa;
$color-smooky-grey: #727272;
$color-davy-grey: #545454;
$color-desert-shadow: #413d39cc;
$color-red: #d14124;
$color-white: #ffffff;
$color-accent-prime: #7e6a54;
$color-blue: rgb(0, 95, 204);
$color-brown: #3d3935;
$color-sinopia-orange: #dc4504ff;
$color-orange: #DC4405;
$color-orange-dark: #c53a04;

$color-primary: $color-greyish-brown;
$color-secondary: $color-grey-light;
$color-secondary-lighter: $color-grey-lighter;
$color-tertiary: $color-grey;
$color-tertiary-dark: #979797;
$color-accent: $color-red;
$color-grey-input: #f2f2f2;
$color-old-silver: #858585;
$color-mercury: #e6e6e6;

// Typography
$color-text: $color-primary;
$color-text-secondary: $color-tertiary;
$color-text-dark: $color-greyish-brown-dark;
$color-text-light: $color-white;
$color-text-accent: $color-accent;

// Backgrounds
$color-background: $color-secondary;
$color-background-grey-transparent: rgba(111, 111, 111, 0.75);
$color-background-dark: $color-primary;
$color-background-light: $color-secondary-lighter;
$color-background-lightest: $color-white;
$color-background-sidebar: $color-greyish-brown;
$color-background-light-transparent: rgba(255, 255, 255, 0.6);

// Borders
$color-border: $color-primary;
$color-border-light: #dfdfdf;
$color-border-lighter: #c6c6c6;
$color-border-silver: #afafaf57;
$color-border-dark: darken($color-border, 20%);

// States
$color-active: $color-greyish-brown-dark;
$color-focus: $color-greyish-brown-dark;
$color-hover: $color-greyish-brown-dark;

// Link colors
$color-link: $color-primary;
$color-link-hover: $color-greyish-brown-dark;
$color-link-active: $color-greyish-brown-dark;

// Validation colors
$color-error: #ff0000;
$color-error-bg: #ffcccc;
$color-error-border: #ff0000;

$color-notice: #666600;
$color-notice-bg: #ffffcc;
$color-notice-border: #666600;

$color-info: #000066;
$color-info-bg: #ccccff;
$color-info-border: #000066;

$color-success: #006600;
$color-success-bg: #ccffcc;
$color-success-border: #006600;

// Sliders
$color-slider-arrow: #6f6f6f;
$color-slider-arrow-bg: hsla(0, 0%, 100%, 0.7);
$color-slider-arrow-disabled-bg: hsla(0, 0%, 100%, 0.3);

// Overlay
$modal-overlay: rgba(0, 0, 0, 0.5);
$modal-overlay-white: rgba(255, 255, 255, 0.5);
$modal-overlay-prime: rgba(61, 57, 53, 0.6);
$modal-overlay-black: rgba(0, 0, 0, 0);
$modal-overlay-dark-white: hsla(0, 0%, 100%, 0.5);
$product-image-out-of-stock-overlay: rgba(61, 57, 53, 0.4);
$product-slider-image-out-of-stock-overlay: rgba(61, 57, 53, 0.1);

//Box-shadow
$box-shadow-black: rgb(0 0 0 / 10%);

// Gradients
$gradient-button: linear-gradient(
  180deg,
  #a38c71 0%,
  #8f7b64 6.25%,
  #685642 100%
);
$gradient-button--dark: linear-gradient(
  180deg,
  #64615d 0%,
  #3d3935 6.25%,
  #181818 100%
);
$gradient-button--red: linear-gradient(
  180deg,
  #cf4023,
  #da4405 10.42%,
  #c54b32 97.4%
);
$gradient-button--orange: linear-gradient(
  180deg,
  #d84d1e,
  #db4505 10.42%,
  #cc521e 97.4%
);
$gradient-button--white: linear-gradient(
  180deg,
  #f5f5f5 0%,
  #ffffff 10.42%,
  #ececec 97.4%
);
$gradient-button--gray: linear-gradient(
  180deg,
  rgba(100, 97, 93, 0.5),
  rgba(61, 57, 53, 0.5) 6.25%,
  rgba(24, 24, 24, 0.5)
);

// inputs
$color-input-placeholder: rgb(144, 162, 189);
$color-input-error: #ff6d6d;
$color-input-tooltip-bg: rgba(51, 51, 51, 0.9);

// icons

$color-svg-red: #db4505;

// badges
$badge-brand-dark: #3d3934;
$badge-quality-yellow: #f0b323;
$badge-brand-light-green: #72a431;
$badge-brand-green: #17763b;
$badge-quality-red: #d14124;
$badge-quality-brown: #4d362b;
$badge-quality-light-gray: #8d9192;
$badge-quality-blue: #011e62;
