.form--customer-address {
  margin-bottom: 1em;
  margin-top: 1em;
}

[data-address='root'] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

[data-line-count='1'] {
  flex-basis: 100%;
}

[data-line-count='2'] {
  flex-basis: 49%;
}

[data-line-count='3'] {
  flex-basis: 32%;
}
