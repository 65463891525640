
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.checkout-fulfillment--delivery {
  @include mq($from: tablet) {
    padding-top: 2em;
  }

  .shipping-address-form__wrapper {
    @include mq($until: tablet) {
      margin-bottom: 2em;
    }
  }
}

.checkout-fulfilmment__addresses-new,
.checkout-fulfillment__address-customer {
  animation: fade-in 0.5s ease forwards;
}

.checkout-fulfillment__addresses-new {
  .action {
    margin-top: 2em;
    padding-left: 1em;
    padding-right: 1em;

    @include mq($until: tablet) {
      display: inline-block;
      margin: 0 0.5em;
      width: auto;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.checkout-fulfillment__addresses-list {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
}

