@import "../../styles/vars/colors";
@import "../../styles/vars/fonts";
@import "../../styles/vars/breakpoints";
@import "~sass-mq";


.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 300px;
  height: 600px;

  @include mq($from: mobile-w, $until: tablet) {
    width: 400px;
    height: 600px;
  }

  @include mq($from: tablet, $until: tablet-w) {
    width: 500px;
    height: 600px;
  }

  @include mq($from: tablet-w, $until: hiw-width) {
    width: 600px;
    height: 600px;
  }

  @include mq($from: hiw-width, $until: laptop) {
    width: 800px;
    height: 600px;
  }

  @include mq($from: laptop, $until: laptop-l) {
    width: 1000px;
    height: 600px;
  }

  @include mq($from: laptop-l, $until: uhd) {
    width: 1300px;
    height: 600px;
  }

  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow:scroll;
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 28px;
  text-align: center;
}

.modalContent {
  padding: 40px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}



.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}





.cancelBtn {
  //margin-top: 10px;
  margin-bottom: -20px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #94c9ff;
  background: #000000;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  color: #48637d;
  background: whitesmoke;
}