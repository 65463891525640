@import "../vars/ui";

button,
input {
  &[type="button"],
  &[type="submit"],
  &[type="reset"] {
    &:not([class]) {
      @include button;
    }
  }
}

.action {
  @include button;
  position: relative;

  &--secondary,
  &--secondary--light {
    background-color: transparent;
    border-color: $color-primary;
    color: $color-accent;
    fill: $color-accent;

    &:hover,
    &:focus {
      background: $color-primary;
    }
  }

  &--primary--light,
  &--secondary--light {
    border-color: $color-white;

    &:hover,
    &:focus {
      background: $color-white;
      border-color: $color-white;
      color: $color-red;
    }
  }

  &--primary--light {
    &:hover,
    &:focus {
      color: $color-text;
      fill: $color-text;
    }
  }

  &--primary--ghost {
    background-color: transparent;
    color: $color-primary;
    fill: $color-primary;

    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $color-white;
      fill: $color-white;
    }
  }

  &--feature,
  &--feature--light {
    background: transparent;
    color: $color-accent;
    border-color: $color-primary;

    &:hover,
    &:focus {
      background: $color-primary;
    }
  }

  &--feature--light {
    border-color: $color-white;

    &:hover,
    &:focus {
      background: $color-white;
    }
  }

  &--subscription {
    color: $color-white;
    background: $gradient-button;
    border-color: $color-accent-prime;
    border-radius: 0.25rem;

    &:hover,
    &:focus {
      background: $gradient-button;
      border-color: $color-accent-prime;
    }
  }

  &--tertiary {
    display: inline-block;
    margin: 1rem;
    color: $color-white;
    background: $gradient-button--dark;
    font-size: 0.75rem;
    padding: 0.95rem;
    border: 1px solid $color-black;
    box-shadow: 0 0.25rem 0.25rem rgba($color-black, 0.1);
    border-radius: 0.25rem;
    text-transform: uppercase;
    line-height: 1.5;
  }

  &--info {
    border-radius: 0.25rem;

    &,
    &:hover,
    &:focus,
    &:active {
      background: $gradient-button--white;
      color: $color-greyish-brown;
      border: 1px solid $color-background;
    }
  }

  &--primary-o {
    border-radius: 0.25rem;

    &,
    &:hover,
    &:focus,
    &:active {
      background: $color-orange;
      color: $color-white;
      border: 1px solid $color-orange;
      border-radius: 2rem !important;
      min-width: 12rem;
    }
  }

  &--info-o {
    border-radius: 0.25rem;

    &,
    &:hover,
    &:focus,
    &:active {
      background: $color-grey-light;
      color: $color-grey-dark;
      border: 1px solid $color-grey-light;
      border-radius: 2rem !important;
      min-width: 12rem;
    }
  }
}

.action__icon {
  position: absolute;
  top: 0;
  left: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background: $color-white;
  fill: $color-text;
  transform: translateY(-50%);
}

.text-input {
  ~ label {
    position: absolute;
    top: 50%;
    left: 0;
  }

  &:focus ~ label,
  &.has-content ~ label {
    top: 5px;
    font-size: 11px;
    transition: 0.2s ease-in-out;
  }
}

.input-group {
  position: relative;
  padding-top: 1.25rem;
  margin-bottom: 1.25rem;

  input,
  select {
    font-size: inherit;
    padding: 3px 0;
    width: 100%;
    border: none;
    border-bottom: solid 1px $color-primary;
  }

  label {
    transition: 0.2s ease-in-out;
    text-transform: none;
    letter-spacing: normal;
  }

  .select-wrapper {
    &::after {
      margin: 0;
    }
  }
}

.select-wrapper {
  label {
    top: -1.25rem;
    font-size: small;
  }
}

.visually-hidden {
  display: none;
}
