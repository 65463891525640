
.checkout-fulfillment-summary {
  background: $color-background;
  margin-top: 1em;

  &,
  p {
    text-align: center;
  }

  a {
    text-decoration: underline;
  }

  .os-step__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 16px 0 5px;

    @include mq($from: mobile-l) {
      font-size: 1.125rem;
    }
  }

  .os-step__title--prime {
    background-color: $color-accent-prime;
    color: $color-white;
    padding: 5px;
  }
}

.fulfillment-summary__subscribed-icon {
  margin-right: 5px;

  svg {
    display: block;
    width: 35px;
    height: 35px;
  }
}

.checkout-fulfillment-summary__content {
  padding: 15px 3em 20px;
}

.delivery-address {
  margin-bottom: 16px;
}

.pickup-store {
  @include mq($from: tablet) {
    display: flex;
    justify-content: space-around;
  }
}

.pickup-store__info {
  margin-top: 1em;

  @include mq($from: tablet) {
    flex-basis: 45%;
  }
}
