.hiw-shopper {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 225px 0 45px;
  background-size: cover;
  background-position: top center;

  &__container {
    position: relative;
    overflow: hidden;
  }

  &__background-image {
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--desktop {
      display: none;
      position: absolute !important;

      @include mq($from: tablet-w) {
        display: block;
      }
    }

    &--mobile {
      --object-position: center !important;

      @include mq($from: tablet-w) {
        display: none;
      }
    }
  }

  @include mq($from: tablet-w) {
    padding: 50px 0 75px;
  }

  &__main-title {
    display: none;

    @include mq($from: tablet-w) {
      position: relative;
      display: block;
      max-width: 500px;
      margin-bottom: 65px;
      font-size: 30px;
      font-weight: 700;
      line-height: 1.33;
      letter-spacing: 0.05px;
      text-align: center;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-bottom: 145px;    
    padding: 45px 0 35px;
    text-align: center;
    color: $color-white;

    @include mq($from: tablet-w) {
      width: auto;
      margin-bottom: 100px;
      padding: 120px 0 105px;
    }
  }

  &__title {
    padding: 0;
    font-size: 26px;
    text-shadow: 1px 0 10px rgba($color-black, 0.8);

    @include mq($from: laptop) {
      font-size: 60px;
    }
  }

  &__pre-title,
  &__post-title {
    position: absolute;
    display: inline-block;
    font-family: $font-heading;
    font-size: 72px;
    line-height: 1;
    font-weight: 700;
    opacity: 0.5;
    white-space: nowrap;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;

    @include mq($from: laptop) {
      font-size: 160px;
    }
  }

  &__pre-title {
    top: -25px;
    left: 0;

    @include mq($from: laptop) {
      top: -60px;
      left: -300px;
    }
  }

  &__post-title {
    right: 0;
    bottom: -35px;

    @include mq($from: laptop) {
      right: -300px;
      bottom: -60px;
    }
  }

  &__content {
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px;

    @include mq($from: tablet-w) {
      position: relative;
    }
  }

  &__qualities {
    display: flex;
    justify-content: space-between;
    max-width: 445px;
    margin: 0 auto 30px;

    @include mq($from: tablet-w) {
      margin: 0 auto 50px;
    }
  }

  &__quality {
    position: relative;
    display: flex;
    flex-direction: column;

    @include mq($from: tablet-w) {
      padding-bottom: 37px;
    }
  }

  &__icon {
    position: relative;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background: $color-white;
    cursor: default;

    @include mq($from: tablet-w) {
      width: 100px;
      height: 100px;
      /* stylelint-disable no-unknown-animations */
      animation: pulse 2s infinite;
      /* stylelint-enable no-unknown-animations */
      will-change: box-shadow;
    }

    &-img {
      display: block;
      width: 100%;
      height: 100%;
    }

    &-title {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: none;
      text-align: center;
      transform: translateX(-50%);
      font-family: $font-heading;
      font-weight: 700;
      text-transform: uppercase;
      white-space: nowrap;

      @include mq($from: tablet-w) {
        display: block;
      }
    }
  }

  &__tooltip {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 210px;
    padding-bottom: 10px;
    border-radius: 50%;
    border-top-right-radius: 0;
    background-color: #ffffff;
    box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
    text-align: center;
    visibility: hidden;
    opacity: 0;

    @include transition($transition-slow, visibility, opacity);

    .hiw-shopper__icon:hover & {
      @include mq($from: tablet-w) {
        visibility: visible;
        opacity: 1;
      }
    }

    &-icon {
      width: 60px;
      height: 60px;
    }

    &-title {
      margin-bottom: 10px;
      font-family: $font-heading;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.13;
      letter-spacing: 0.04px;
      text-transform: uppercase;
    }

    &-text {
      width: 150px;
      font-size: 14px;
      line-height: 1.43;
    }
  }

  &__description {
    position: relative;
    max-width: 255px;
    margin: 0 auto;
    text-align: center;
    font-family: $font-heading;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;

    @include mq($from: tablet-w) {
      max-width: 535px;
      text-transform: none;
    }

    &--mobile {
      @include mq($from: tablet-w) {
        display: none;
      }
    }

    &--desktop {
      @include mq($until: tablet-w) {
        display: none;
      }
    }
  }
}
