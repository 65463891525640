@import './vars/fonts';
@import './vars/colors';

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  font-family: $font-body;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: $font-heading;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: $font-heading;
}

.red--text {
  color: $color-red
}
