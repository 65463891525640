
.subscription-modal {
  overflow: hidden;
  padding: 0 !important;

  &__wrapper {
    z-index: 2000;
  }

  &-modal__overlay {
    background-color: $modal-overlay-prime
  }

  &-modal__content {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    box-shadow: none;
    text-align: center;

    @include mq($from: tablet) {
      max-width: 658px;
      height: auto;
      padding: 1.25rem;
    }
  }

  &__icon {
    display: inline-block;
    height: 100px;
    width: 100px;
  }

  &__title {
    font-size: 1.625rem;
    padding: 0;
    line-height: 1.875rem;
    margin-bottom: 0.875rem;
  }

  &__subtitle {
    display: block;
    font-size: 1rem;
    margin-top: 15px;;
  }

  &__body {
    max-width: 360px;
    margin: auto;
  }

  &__postal-code {
    margin-left: 0.3125rem;
  }

  &__footer {
    margin: 1rem auto 1.5625rem;
    max-width: 360px;
    display: flex;
    flex-direction: column;
  }

  &__footer-description {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
  }

  &__signup {
    box-sizing: border-box;
    background: $gradient-button;
    border: 1px solid $color-accent-prime;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    cursor: pointer;
    width: 178px;
    color: $color-white;
    font-family: $font-heading;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0.5rem auto 0.75rem;
    min-height: 3.66666em;
    min-width: 12em;
    padding: 1em 1.25em;
  }

  &__learn {
    box-sizing: border-box;
    background: $gradient-button--white;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
    border: 1px solid $color-grey-light;
    border-radius: 0.25rem;
    width: 178px;
    color: $color-greyish-brown;
    font-family: $font-heading;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0.5rem auto 1rem;
    min-height: 3.66666em;
    min-width: 12em;
    padding: 1em 1.25em;
  }

  &__close {
    background-color: transparent;
    border: 0;
    margin-top: 0.625rem;
    font-size: 0.75rem;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
}
