
.layout--checkout {

  .price-notice {
    color: $color-accent;
    font-size: 0.75em;
    margin: 0;
    padding: 0;

    @include mq($from: tablet) {
      font-size: 0.875em;
      font-style: italic;
      font-weight: 700;
      text-align: right;
    }
  }

  .total-line__name:not(.payment-due-label) {
    font-family: $font-body;
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: 0.3px;
    text-align: left;
    color: $color-text;
    width:100%
  }

  .total-line__price-compare {
    text-decoration: line-through;
    color: $color-grey;
  }

  .payment-due-label__total {
    font-family: $font-body;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.94;
    letter-spacing: 0.01875em;
    color: $color-text;
  }

  .payment-due__currency {
    font-family: $font-body;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.94;
    letter-spacing: 0.01875em;
    color: $color-text;
    text-transform: uppercase;
  }

  .payment-due__price {
    font-family: $font-body;
    font-size: 1.3125em;
    font-weight: 500;
    line-height: 1.48;
    letter-spacing: 0.01428em;
    color: $color-text;
  }
}
