
.checkout-fulfillment-option__title {
  [disabled] & {
    opacity: 0.5;
  }

  @include mq($until: tablet) {
    display: block;
    font-size: 1.112em;
  }
}

.checkout-fulfillment-option__description {
  display: block;
  font-family: $font-body;
  font-size: 0.556em;
  font-weight: 300;
  line-height: normal;
  text-transform: none;

  @include mq($from: tablet) {
    font-family: $font-body;
    font-size: 0.778em;
    font-weight: 300;
    line-height: normal;
    text-transform: none;
  }
}

.checkout-fulfillment-option__warning {
  color: $color-text-dark;
  font-weight: 500;

  .checkout-step--single_method & {
    display: none;
  }

  .warning__currency {
    color: $color-text-accent;
  }
}
